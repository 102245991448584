<ng-container *ngIf="mainImageBase64$ | async as mainImageBase64; else noImageTpl">
  <div data-testid="grid-item-image" class="development-item-thumb__container" (click)="onImageThumbClick($event)">
    <app-image [base64]="mainImageBase64"></app-image>
  </div>
</ng-container>
<ng-template #noImageTpl>
  <div class="development-item-thumb__placeholder-img-container">
    <i
      class="grid-item__image-placeholder"
      [ngClass]="{
        'experiment-placeholder': developmentType === DevelopmentType.experiment,
        'research-object-placeholder': developmentType === DevelopmentType.researchObject
      }"
    ></i>
  </div>
</ng-template>

<ng-template #imageDetailsModal>
  <app-item-image-details
    [image]="mainLargeImage$ | async"
    [developmentType]="developmentType"
    [categoryName]="categoryName"
    [developmentItemName]="developmentItem?.name"
    [editMode]="imageModalEditMode"
    [isMain]="true"
    (closeBtnClick)="handleImageModalCloseBtnClick()"
    (descriptionChanged)="handleImageModalDescriptionChange($event)"
    (editModeChange)="onImageModalEditModeChange($event)"
  />
</ng-template>
