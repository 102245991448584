<div class="member-popover">
  <div class="member-popover__subheader">{{ role() }}</div>
  <div>{{ roleDescription() }}</div>
  @if (unableTo()?.length) {
    <div class="member-popover__subheader member-popover__subheader--warning mt-2">Cannot:</div>
    <ul class="member-popover__list">
      @for (unableToRule of unableTo(); track unableToRule) {
        <li>{{ unableToRule }}</li>
      }
    </ul>
  }
</div>
