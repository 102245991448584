import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-item-details-skeleton',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './item-details-skeleton.component.html',
  styleUrls: ['./item-details-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemDetailsSkeletonComponent {}
