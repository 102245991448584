import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, EventEmitter, Input, Output, signal } from '@angular/core';
import { AsyncPipe, CommonModule, NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { Store } from '@ngrx/store';

import { trackByField } from '@shared/_helpers';
import {
  CategoryTemplate,
  DevelopmentType,
  DisplayFieldType,
  ErrorMessageSection,
  Field,
  FieldsViewMode,
  UpdateArea,
  PreprocessedErrorDto
} from '@shared/_models';
import { DYNAMIC_FORM } from '../../../../dynamic-form';
import { LabelPipe } from '@app/shared/pipes';
import { IsTextLongPipe } from '@app/shared/pipes/is-text-long.pipe';
import { isPartOfPepseq } from '@app/domain/field';
import { ContentWithCopyBtnComponent } from '@shared/_components/content-with-copy-btn/content-with-copy-btn.component';
import { selectTemplateParameterById } from '@shared/_root-store/category-templates-store/category-templates.selectors';
import { FieldValuePreviewComponent } from './field-value-preview/field-value-preview.component';
import { FieldConfig } from '@shared/dynamic-form/models';
import { FieldsErrors } from '@shared/dynamic-form/components/dynamic-field/utils';
import { PepseqFormGroupComponent } from '@shared/_components/item/pepseq-form-group/pepseq-form-group.component';
import { FieldKind, FieldViewData, NonComplexFieldViewData } from '@app/domain/field/to-fields-view-data';
import { toMessage } from '@app/domain/error-handling';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-item-details-grid-section',
  standalone: true,
  imports: [
    PepseqFormGroupComponent,
    ContentWithCopyBtnComponent,
    NgClass,
    NgFor,
    NgIf,
    LabelPipe,
    IsTextLongPipe,
    AsyncPipe,
    TitleCasePipe,
    FieldValuePreviewComponent,
    CommonModule,
    ...DYNAMIC_FORM
  ],
  providers: [TitleCasePipe],
  templateUrl: './item-details-grid-section.component.html',
  styleUrls: ['./item-details-grid-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemDetailsGridSectionComponent {
  @Input() categoryTemplate: CategoryTemplate;
  @Input() mode: FieldsViewMode;

  @Input() set gridData(value: FieldViewData[]) {
    this.#onGridDataChange(value);
  }
  gridDataSignal = signal<FieldViewData[]>([]);

  @Input() set errorMessage(value: ErrorMessageSection) {
    this.#errorMessageSignal.set(value);
  }
  #errorMessageSignal = signal<ErrorMessageSection>(null);

  @Input() pendingArea: UpdateArea;
  @Input() pepseqErrors: PreprocessedErrorDto;

  @Output() errorsChange: EventEmitter<FieldsErrors> = new EventEmitter<FieldsErrors>();

  get developmentType(): DevelopmentType {
    return this.categoryTemplate?.category?.development_type;
  }

  errors = computed<string[]>(() => {
    if (!this.gridDataSignal()?.length || !this.#errorMessageSignal()?.errorResponse) return [];

    if (typeof this.#errorMessageSignal().errorResponse === 'boolean') return [];

    return this.gridDataSignal()
      .filter((data): data is NonComplexFieldViewData => data.type === FieldKind.NON_COMPLEX)
      .map(data => toMessage(<HttpErrorResponse>this.#errorMessageSignal().errorResponse, data.field.field_template_id, null));
  });

  sequenceOrSmilesPresent = false;
  formTouched = signal(false);

  readonly trackByField = trackByField;
  readonly selectTemplateParameterById = templateParameterId =>
    this.store.select(selectTemplateParameterById(this.categoryTemplate.id, templateParameterId));
  readonly DevelopmentType = DevelopmentType;
  readonly FieldsViewMode = FieldsViewMode;
  readonly DisplayFieldType = DisplayFieldType;
  readonly UpdateArea = UpdateArea;
  readonly isPartOfPepseq = isPartOfPepseq;

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
    private titlecasePipe: TitleCasePipe
  ) {}

  onNonComplexFieldValueChange(value: string, field: Field) {
    const gridItem = this.gridDataSignal().find(
      (data): data is NonComplexFieldViewData =>
        data.type === FieldKind.NON_COMPLEX && data.field.field_template_id === field.field_template_id
    );
    gridItem.field.value = value;
  }

  onFormTouched(touched: boolean) {
    const hasChanged = this.formTouched() !== touched;
    this.formTouched.set(touched);
    // Refreshes formTouched inside ngFor elememts
    if (hasChanged) {
      this.cdRef.detectChanges();
    }
  }

  isBig(card: FieldConfig): boolean {
    return [DisplayFieldType.SMILES, DisplayFieldType.SEQUENCE, DisplayFieldType.TEXT_LONG, DisplayFieldType.WYSIWYG].includes(card.type);
  }

  #onGridDataChange(value: FieldViewData[]) {
    if (value === this.gridDataSignal()) return;

    this.gridDataSignal.set(value);
  }
}
