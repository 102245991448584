<div class="file-sharing-development-items">
  @if (showDevelopmentItemSearch()) {
    <div class="input-group">
      <app-search-select
        [controlName]="FORM_KEYS.DEVELOPMENT_ITEMS_DROPDOWN_CONTROL"
        [form]="developmentItemsDropdown"
        [searchFunction]="searchDevelopmentItems"
        [resultTemplate]="developmentItemsResultTemplate"
        placeholder="To add type ID or name ..."
        (selectItem)="onLink($event)"
      ></app-search-select>
    </div>
  } @else {
    <app-button class="workspace-tertiary w-100 h-45" (click)="showDevelopmentItemSearch.set(true)">
      <span class="file-sharing-add-btn"
        >+ Add {{ (selectedProject$ | async)?.development_type_names.research_objects }} or
        {{ (selectedProject$ | async)?.development_type_names.experiments }}</span
      >
    </app-button>
  }
  <div class="scrollable-container">
    <div class="container-items">
      @for (developmentItem of items(); track developmentItem.oid; let i = $index) {
        <app-file-sharing-development-item
          [fileId]="fileId()"
          [fileName]="fileName()"
          [developmentItem]="developmentItem"
          [developmentItemsLength]="items().length"
          [index]="i"
          (unlink)="onUnlink(developmentItem)"
          class="scrollable-container__group__items__item"
        ></app-file-sharing-development-item>
      }
    </div>
  </div>
</div>

<ng-template #developmentItemsResultTemplate let-developmentItem="result" let-searchTerm="term">
  @if (developmentItem) {
    <div class="development-item">
      <span class="development-item__badge development-item__badge--{{ developmentItem.development_type }}">
        <span
          class="development-item__badge__text"
          [ngbTooltip]="developmentItem.oid"
          appOverflowContentPluginToNgbTooltip
          defaultTooltipValue="Name"
          >{{ developmentItem.oid }}</span
        >
      </span>
      <div class="development-item__info">
        <span
          class="development-item__info__name"
          [ngbTooltip]="developmentItem.name"
          appOverflowContentPluginToNgbTooltip
          defaultTooltipValue="Name"
          >{{ developmentItem.name }}</span
        >
        <span class="development-item__info__category-name">{{ developmentItem.category_name }}</span>
      </div>
    </div>
  } @else {
    <div>
      No {{ (selectedProject$ | async)?.development_type_names.research_objects }}/{{
        (selectedProject$ | async)?.development_type_names.experiments
      }}
      found with a name containing "{{ searchTerm }}".
    </div>
  }
</ng-template>
