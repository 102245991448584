import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { CategoryTemplateDto, ExperimentDto, RouteParam } from '@shared/_models';
import { selectTemplateById } from '@shared/_root-store/category-templates-store/category-templates.selectors';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors/router.selectors';
import { ExperimentsApiService } from '@shared/_services/experiment/experiments-api.service';

export function getGoToExperimentArgs(): (
  experimentId: string,
  preserveQueryParams?: boolean
) => Observable<[string, string, string, string, boolean]> {
  const experimentsApiService = inject(ExperimentsApiService);
  const workspaceService = inject(WorkspaceService);
  const store = inject(Store);

  return (experimentId: string, preserveQueryParams = false) =>
    experimentsApiService.get(experimentId).pipe(
      switchMap(experiment =>
        combineLatest([
          store.select(selectTemplateById(experiment.category_template_id)),
          store.select(selectRouteParam(RouteParam.PROJECT_ID))
        ]).pipe(map(([template, projectId]) => [experiment, template, projectId]))
      ),
      map(([_, template, projectId]: [ExperimentDto, CategoryTemplateDto, string]) => [
        workspaceService.currentWorkspaceId(),
        projectId,
        experimentId,
        template.category_id,
        preserveQueryParams
      ])
    );
}
