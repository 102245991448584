import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { toObservable } from '@angular/core/rxjs-interop';

import { DevelopmentType, SectionType, RouteParam, CategoryTemplate, ProjectDto } from '@shared/_models';
import { selectCurrentRoute, selectRouteDataParam, selectRouteParam } from '@shared/_root-store/router-store/selectors/router.selectors';
import {
  selectCategoriesDictionary,
  selectCategoryById,
  categoriesLoading
} from '@shared/_root-store/categories-store/categories.selectors';
import { selectTemplateByCategoryId } from '@shared/_root-store/category-templates-store/category-templates.selectors';
import { selectSelectedProject } from '@shared/_root-store/projects-store/projects.selectors';
import { ROOT_CATEGORY_ID } from '@app/domain/category';
import { editedCategory } from '@app/navigation/secondary-navigation/secondary-navigation.store';
import { toFields } from '@shared/dto-adapters/field';
import { RouteData } from '@shared/_models/route-data';

// I had to create this service to make tests working, exactly: selectTemplateByCategoryId selector
@Injectable({
  providedIn: 'root'
})
export class TemplateStoreService {
  constructor(private readonly store: Store) {}

  #editedCategory$ = toObservable(editedCategory);

  // info o development type w routingu może pochodzić albo z route param albo z route data param
  developmentType$: Observable<DevelopmentType> = combineLatest([
    this.store.select(selectRouteParam(RouteData.DEVELOPMENT_TYPE)),
    this.store.select(selectRouteDataParam(RouteData.DEVELOPMENT_TYPE))
  ]).pipe(
    map(([developmentTypeParam, developmentTypeData]: [DevelopmentType, DevelopmentType]) => {
      return developmentTypeParam || developmentTypeData;
    })
  );
  template$: Observable<CategoryTemplate> = this.store
    .select(selectRouteParam(RouteParam.CATEGORY_ID))
    .pipe(
      switchMap(categoryId =>
        combineLatest([this.store.select(selectTemplateByCategoryId(categoryId)), this.store.select(selectCategoryById(categoryId))]).pipe(
          map(([template, category]) => (template && category ? { ...template, category } : null))
        )
      )
    );
  project$: Observable<ProjectDto> = this.store.select(selectSelectedProject);
  categoryName$ = combineLatest([
    this.store.select(selectCategoriesDictionary),
    this.store.select(categoriesLoading),
    this.store.select(selectCurrentRoute),
    this.project$,
    this.#editedCategory$,
    this.developmentType$
  ]).pipe(
    filter(([_, categoriesLoading]) => !categoriesLoading),
    map(([categoriesDictionary, _, route, project, editedCategory, developmentType]) => {
      const { categoryId } = route?.params ?? {};

      if (editedCategory) return editedCategory.name;

      if (categoryId === ROOT_CATEGORY_ID)
        return developmentType === DevelopmentType.researchObject
          ? project.development_type_names.research_objects
          : project.development_type_names.experiments;

      return categoriesDictionary[categoryId]?.name;
    })
  );
  instanceFields$ = this.template$.pipe(
    map(template => {
      const section = template?.sections?.find(section => section.type === SectionType.INSTANCES);

      if (!section) return;

      return toFields(section.fields);
    })
  );

  imageAreaHidden$ = this.template$.pipe(
    filter(template => !!template),
    map(template => template.hidden_section_types?.includes(SectionType.IMAGES))
  );

  readonly parametersAreaHidden$ = this.template$.pipe(
    filter(template => !!template),
    map(template => template.hidden_section_types?.includes(SectionType.PARAMETERS))
  );

  readonly instanceAreaHidden$ = this.template$.pipe(
    filter(template => !!template),
    map(template => template.hidden_section_types?.includes(SectionType.INSTANCES))
  );

  readonly resultsAreaHidden$ = this.template$.pipe(
    filter(template => !!template),
    map(template => template.hidden_section_types?.includes(SectionType.RESULTS))
  );
}
