import { Pipe, PipeTransform } from '@angular/core';

import { ISortOption } from '../_models';

@Pipe({
  name: 'sortingRotationState',
  standalone: true
})
export class SortingRotationStatePipe implements PipeTransform {
  transform(prop: string, sort: ISortOption): string {
    const sortProp = sort.prop || sort.parameter_id;

    if (prop.toString() === '' || prop !== sortProp) {
      return 'no-sorting';
    }

    return sort.order;
  }
}
