<div class="info-modal info-modal--project">
  <app-generic-modal
    [closeButton]="{ text: 'Cancel' }"
    [acceptButton]="{ text: 'Update list and close' }"
    [developmentType]="DevelopmentType.project"
    [primary]="true"
    [hideHeaderCloseIcon]="true"
    [hideFooter]="true"
    (closeModal)="close()"
  >
    <ng-template #headerTemplate>
      <ng-template [ngTemplateOutlet]="modalHeaderTemplate"></ng-template>
    </ng-template>
    <div class="info-modal__body">
      <span class="project-members-label">Project Members</span>
      <app-button *ngIf="!showMembersSearch" class="project-tertiary w-100 h-45" (click)="showMembersSearch = true">
        + Add Member
      </app-button>
      <app-members-dropdown *ngIf="showMembersSearch" [members]="members()" (memberAdd)="onMemberAdd($event)"> </app-members-dropdown>
      <app-members-elements [users]="members()" [projectProxy]="projectProxy()"></app-members-elements>
    </div>
  </app-generic-modal>
</div>

<ng-template #modalHeaderTemplate>
  <app-modal-header-left
    header="LabGears"
    imgSrc="project_icon.svg"
    itemName="Project"
    [developmentType]="DevelopmentType.project"
    [categoryName]="header() || 'New'"
    (closeModal)="closeModal()"
  ></app-modal-header-left>
</ng-template>
