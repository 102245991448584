<div [ngClass]="{ 'dynamic-field-border': config()?.isGridView }" class="form-member">
  <app-input-with-validation
    data-testid="input-field"
    [control]="control()"
    [config]="config()"
    [backendErrorMessage]="backendErrorMessage()"
    [externalTouched]="externalTouched()"
  >
    <div class="form-member__select">
      <app-user-avatar [imageBase64]="selectedAvatar$ | async" avatarSize="21px"></app-user-avatar>
      <ng-select
        #select
        [formControl]="control()"
        [clearable]="false"
        [items]="items$ | async"
        [typeahead]="searchTerms$"
        [class.invalid]="markAsInvalid()"
        (blur)="blur$.next()"
        bindValue="id"
        placeholder="Select..."
      >
        <ng-template ng-label-tmp let-item="item">
          <span class="dropdown-select-label">{{ toLabel(item, select) }}</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <div data-testid="dropdown-option" class="form-member__select__option">
            <app-user-avatar [imageBase64]="item.avatar" avatarSize="30px"></app-user-avatar>
            <div>
              <div class="form-member__select__option-name">{{ item.name }} {{ item.surname }}</div>
              <div class="form-member__select__option-email">{{ item.email }}</div>
            </div>
          </div>
        </ng-template>
      </ng-select>
      <button *ngIf="this.control().value" (click)="onClear()" class="btn btn-clear"></button>
    </div>
  </app-input-with-validation>
</div>
