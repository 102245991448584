import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActionReducer, createReducer, on } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { ProjectDto } from '@shared/_models';
import * as actions from './projects.actions';

export const PROJECTS_STATE_KEY = 'projects';
export const projectsAdapter: EntityAdapter<ProjectDto> = createEntityAdapter<ProjectDto>();
export interface ProjectsState extends EntityState<ProjectDto> {
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly error: HttpErrorResponse;
}

export const initialState: ProjectsState = projectsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

export const reducer: ActionReducer<ProjectsState> = createReducer(
  initialState,

  on(actions.loadProjects, state => {
    return { ...state, loading: true, loaded: false, error: null };
  }),
  on(actions.loadProjectsSuccess, (state, action) => {
    return projectsAdapter.setAll(action.data, {
      ...state,
      loading: false,
      loaded: true
    });
  }),
  on(actions.loadProjectsFailure, (state, action) => {
    return { ...state, loading: false, loaded: false, error: action.errorResponse };
  }),
  /* we just update/add project loaded by route quard,
   * source of truth about selected project is in URL */
  on(actions.selectProject, (state, action) => {
    return projectsAdapter.upsertOne(action.project, state);
  }),
  on(actions.updateProject, state => {
    return { ...state, error: null };
  }),
  on(actions.updateProjectSuccess, (state, action) => {
    return projectsAdapter.updateOne({ id: action.data.id, changes: action.data }, state);
  }),
  on(actions.updateProjectFailure, (state, action) => {
    return { ...state, error: action.errorResponse };
  }),
  on(actions.resetError, state => {
    return { ...state, error: null };
  })
);
