<div class="input-with-validation">
  <div class="input-with-validation__label">
    <label
      *ngIf="config?.label"
      [ngClass]="{
        'label--invalid': (control.touched || externalTouched) && (control.invalid || backendErrorMessage)
      }"
      class="col-sm-3 label"
    >
      {{ config?.label }}
    </label>
    @if (infoPopover) {
      <app-info-popover [data]="infoPopover"></app-info-popover>
    }
  </div>
  <ng-content></ng-content>
  <div
    data-testid="invalid-error"
    class="invalid-feedback d-flex"
    *ngIf="(control.touched || externalTouched) && (control.invalid || backendErrorMessage)"
  >
    <div *ngIf="control.errors?.maxlength">
      {{ config?.name }} must contain less than {{ control.errors.maxlength.requiredLength }} characters.
    </div>
    <div *ngIf="control.errors?.required">{{ config?.name }} is required.</div>
    <div *ngIf="control.errors?.whiteSpaceOnly">No leading or trailing spaces in {{ config?.name }} allowed.</div>
    <div *ngIf="control.errors?.singleSpaceBetween">Only one space between words in {{ config?.name }} allowed.</div>
    <div *ngIf="control.errors?.nonNumeric">Please enter only numeric values.</div>
    <div *ngIf="control.errors?.max">Please provide value less than {{ control.errors?.max.max }}.</div>
    <div *ngIf="control.errors?.min">Please provide value greater than {{ control.errors?.min.min }}.</div>
    <div *ngIf="backendErrorMessage">{{ backendErrorMessage }}</div>
  </div>
</div>
