import { AsyncPipe, LowerCasePipe, NgClass, NgIf } from '@angular/common';
import { Component, computed, input, output } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { DropdownComponent } from '@shared/_components/dropdown/dropdown.component';
import { DropdownOption } from '@shared/_components/dropdown/utils';
import { GenericModalComponent } from '@shared/_components/generic-modal/generic-modal.component';
import { IconComponent } from '@shared/_components/icon/components/icon/icon.component';
import { IconColors } from '@shared/_components/icon/utils/icon-colors';
import { InfoPopoverComponent } from '@shared/_components/info-popover/info-popover.component';
import { InfoPopoverProps } from '@shared/_components/info-popover/model';
import { ModalHeaderCenterComponent } from '@shared/_components/modal-header-center/modal-header-center.component';
import { UserAvatarComponent } from '@shared/_components/user-avatar/user-avatar.component';
import { MemberEdit, MemberRole, ProjectProxy, UserAvatar } from '@shared/_models';

import { toDropdownOption } from './utils';

@Component({
  selector: 'app-member-element',
  standalone: true,
  imports: [
    AsyncPipe,
    LowerCasePipe,
    NgClass,
    NgIf,
    DropdownComponent,
    UserAvatarComponent,
    IconComponent,
    GenericModalComponent,
    ModalHeaderCenterComponent,
    NgbPopover,
    InfoPopoverComponent
  ],
  templateUrl: './member-element.component.html',
  styleUrls: ['./member-element.component.scss']
})
export class MemberElementComponent {
  member = input.required<MemberEdit>();
  index = input.required<number>();
  avatar = input.required<UserAvatar>();
  projectProxy = input<ProjectProxy>();
  infoPopover = input<InfoPopoverProps>(null);

  memberIsOwner = computed(() => this.member().roleBeforeEdit === this.MemberRole.owner);
  memberIsLead = computed(() => this.member().roleBeforeEdit === this.MemberRole.lead);
  badge = computed(() => this.member()?.roleAfterEdit?.[0]);
  editableRoles = computed<DropdownOption[]>(() => {
    if (this.projectProxy().isOwner) {
      return [MemberRole.owner, MemberRole.lead, MemberRole.editor, MemberRole.reader].map(toDropdownOption);
    }

    const member = this.member();

    if (member?.roleBeforeEdit == MemberRole.owner || member?.roleBeforeEdit == MemberRole.lead) {
      return [member.roleBeforeEdit].map(toDropdownOption);
    }

    return [MemberRole.editor, MemberRole.reader].map(toDropdownOption);
  });

  memberDelete = output<string>();
  editRole = output<MemberRole>();

  readonly IconColors = IconColors;
  readonly MemberRole = MemberRole;

  onEditMemberRole(roleAfterEdit: MemberRole): void {
    if (this.member().roleBeforeEdit === roleAfterEdit) {
      return;
    }
    this.editRole.emit(roleAfterEdit);
  }
}
