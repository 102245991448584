import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';

import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { GENERIC_ERROR_MESSAGE, toMessage } from '@app/domain/error-handling';
import { ToastService } from '@shared/_modules/toast/toast.service';
import { NavigationService } from '@shared/_services/navigation.service';

import { notifyAboutError, navigateToDefaultRoute } from './app.actions';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private toastService: ToastService,
    private navigationService: NavigationService,
    private workspaceService: WorkspaceService
  ) {}

  notifyAboutError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(notifyAboutError),
        tap(({ notification, errorResponse }) => {
          const getToastProps = (): typeof GENERIC_ERROR_MESSAGE => {
            if (notification) return notification;

            if (errorResponse)
              return {
                content: toMessage(errorResponse),
                header: GENERIC_ERROR_MESSAGE.header
              };

            return GENERIC_ERROR_MESSAGE;
          };

          const { content, header } = getToastProps();

          this.toastService.show(content, {
            header,
            type: 'danger',
            delay: 5000
          });
        })
      ),
    { dispatch: false }
  );

  navigateToDefaultRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateToDefaultRoute),
        switchMap(() =>
          this.workspaceService.getInitialWorkspace().pipe(tap(workspace => this.navigationService.goToProjectsList(workspace.id)))
        )
      ),
    { dispatch: false }
  );
}
