<app-project-grid-card
  [project]="itemData()"
  [projectProxy]="projectProxy$ | async"
  (edit)="openModal(editAddProjectModal)"
  (leave)="openModal(projectActionsModal, ACTION_TYPE.LEAVE)"
  (manageMembers)="openModal(projectMembersModal)"
  (join)="openModal(joinProject)"
  (openProject)="onProjectCardClick()"
></app-project-grid-card>

<ng-template #editAddProjectModal let-modal>
  <app-project-edit-add
    [selectedProject]="projectDraft()"
    [modalType]="ModalType.EDIT"
    (closeModal)="onClose(modal)"
  ></app-project-edit-add>
</ng-template>
<ng-template #projectActionsModal let-modal>
  <app-project-actions-modal
    [projectName]="projectDraft().name"
    [actionType]="projectActionType"
    (closeModal)="modal.close()"
    (takeProjectAction)="onTakeProjectAction(projectActionType, modal)"
  ></app-project-actions-modal>
</ng-template>
<ng-template #projectMembersModal let-modal>
  <app-project-members-edit-modal [modalRef]="modal" [projectId]="projectDraft().id"></app-project-members-edit-modal>
</ng-template>
<ng-template #joinProject let-modal>
  <app-project-actions-modal
    [actionType]="ACTION_TYPE.JOIN"
    [projectName]="projectDraft().name"
    (closeModal)="modal.close()"
    (takeProjectAction)="onTakeProjectAction(ACTION_TYPE.JOIN, modal)"
  ></app-project-actions-modal>
</ng-template>
