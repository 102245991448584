import { Directive, Input, HostListener } from '@angular/core';

import { ScrollManager } from './scroll-manager';

@Directive({
  selector: '[appScrollAnchor]',
  standalone: true
})
export class ScrollAnchorDirective {
  @Input('appScrollAnchor') id: string | number;

  constructor(private manager: ScrollManager) {}

  @HostListener('click')
  scroll() {
    this.manager.scroll(this.id);
  }
}
