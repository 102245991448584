<div class="lg-files-modal">
  <div class="lg-files-modal__icon-container">
    <span class="lg-files-modal__icon" [ngClass]="fileCategory | filesModalIconCSSClass: developmentType"></span>
  </div>
  <h2 data-testid="add-files-modal-header" *ngIf="fileCategory === 'files'" class="lg-files-modal__header">Adding files</h2>
  <h2 data-testid="add-images-modal-header" *ngIf="fileCategory === 'images'" class="lg-files-modal__header">
    Adding images to the {{ sectionName }}
  </h2>
  <div
    data-testid="file-drop-area"
    class="lg-files-modal__drop-area"
    (drop)="dropHandler($event)"
    (dragover)="dragOverHandler($event)"
    (dragenter)="dragEnterHandler($event)"
    (dragleave)="dragLeaveHandler($event)"
  >
    <input
      data-testid="add-file-input"
      type="file"
      class="d-none lg-files-modal__input"
      id="fileInput"
      multiple
      [attr.accept]="fileCategory | fileUploadLimitAcceptedExtensions"
      (change)="onFileSelected($event)"
    />
    <div class="lg-files-modal__drop-text">
      <img src="../../../../../assets/icons/file_upload_icon.svg" alt="file upload icon" />
      <div class="mt-2">
        <span data-testid="modal-upload-info" class="lg-files-modal__upload-info">
          To add {{ fileCategory }}
          <br />
          @if (fileCategory === FileCategory.files) {
            drop it here,
            <ng-container *ngTemplateOutlet="browseBtnLabel"></ng-container>
            , or ...
          } @else {
            drop it here or
            <ng-container *ngTemplateOutlet="browseBtnLabel"></ng-container>
            to upload
          }
          <ng-template #browseBtnLabel>
            <label data-testid="browse-files-button" class="lg-files-modal__browse-btn" for="fileInput">browse</label>
          </ng-template>
        </span>
      </div>
      <div class="lg-files-modal__upload-limit">max 10 files up to 50 mb</div>
    </div>
  </div>
  @if (fileCategory === FileCategory.files) {
    <div class="lg-files-modal__share-info">
      <a (click)="onShareFiles()">
        <img src="./assets/icons/share.svg" class="lg-files-modal__share-icon" />
        <span class="mx-2"
          >Share file from another {{ selectedProject()?.development_type_names.research_objects }} or
          {{ selectedProject()?.development_type_names.experiments }}</span
        >
      </a>
      <span class="lg-files-modal__tooltip-container" [ngbPopover]="popoverContent" popoverTitle="File sharing">
        <img src="./assets/icons/info_dark_2_icon.svg" />
        <ng-template #popoverContent>
          You can share a file by adding it from another {{ selectedProject()?.development_type_names.research_objects }}/{{
            selectedProject()?.development_type_names.experiments
          }}. A shared file can be changed and updated, which will result in changes in all shared locations. Shared files can be managed
          from the list view in {{ selectedProject()?.development_type_names.research_objects }}/{{
            selectedProject()?.development_type_names.experiments
          }}
          details.
        </ng-template>
      </span>
    </div>
  }
  <div class="row lg-files-modal__added-files">
    <div class="col-lg-4 mb-2" *ngFor="let fileState of fileUploadStatuses(); let i = index; trackBy: trackByGUID">
      <app-file-upload-card
        [fileName]="fileState.fileToUpload.name"
        [fileSize]="fileState.fileToUpload.size"
        [fileExtension]="fileState.fileToUpload.type"
        [uploadEvents$]="fileState.uploadHttpEvents$"
        [disabled]="anyUploadPending()"
        (uploadCancel)="onUploadCancel(i)"
        (uploadSuccess)="onUploadSuccess(i, $event)"
        (uploadInProgress)="onUploadInProgress(i)"
        (uploadFail)="onUploadFail(i)"
        (remove)="onFileRemove($event, i)"
      ></app-file-upload-card>
    </div>
  </div>
  <footer class="d-flex">
    <ng-container *ngIf="fileCategory === 'images'; else filesView">
      <app-button
        [class]="developmentType + '-tertiary w-100 h-45 mr-8'"
        *ngIf="fileCategory === 'images'"
        [disabled]="anyUploadPending()"
        (click)="onListOfAllImagesClick()"
        data-test="imagesUploadButton"
      >
        {{ anyUploadPending() ? 'Uploading...' : 'Image manager' }}
      </app-button>
      <app-button
        [class]="developmentType + '-tertiary w-100 h-45 ml-8'"
        (click)="onCloseBtnClick()"
        [disabled]="anyUploadPending()"
        data-test="imagesCloseButton"
      >
        {{ anyUploadPending() ? 'Uploading...' : 'Close' }}
      </app-button>
    </ng-container>
    <ng-template #filesView>
      <app-button
        [class]="developmentType + '-tertiary w-100 h-45'"
        (click)="onCloseBtnClick()"
        [disabled]="anyUploadPending()"
        data-test="filesCloseButton"
      >
        {{ anyUploadPending() ? 'Uploading...' : 'Close' }}
      </app-button>
    </ng-template>
  </footer>
</div>
