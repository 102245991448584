<app-item-details-section
  [editMode]="instanceViewMode() === InstanceViewMode.EDIT"
  [addMode]="instanceViewMode() === InstanceViewMode.ADD"
  [showEditBtn]="false"
  [sectionName]="areaName()"
  [activeArea]="activeArea()"
  class="item-details-section"
  [class.item-details-section--edit]="[InstanceViewMode.EDIT, InstanceViewMode.ADD].includes(instanceViewMode())"
>
  <ng-container header-elements>
    <div *ngIf="activeArea()" class="item-details-section__section-name">{{ sectionName() }}</div>
    <app-item-details-instances-dropdown
      *ngIf="instanceViewMode() === InstanceViewMode.READ && researchObjectSignal().instances.length"
      [researchObject]="researchObjectSignal()"
      [activeInstance]="activeInstance"
      [areaName]="areaName()"
      (onActiveInstanceChange)="onActiveInstanceChange($event)"
    ></app-item-details-instances-dropdown>
    <div
      *ngIf="editable() && researchObjectSignal().instances.length && instanceViewMode() === InstanceViewMode.READ"
      class="instance-action-btns"
    >
      <button class="instance-action-btn" (click)="onDeleteBtnClick()">
        <img src="../../../../../../assets/icons/trash_3_icon.svg" class="me-1" alt="remove icon" />
        <span>Delete</span>
      </button>
      <button class="instance-action-btn instance-action-btn--highlighted mx-3" (click)="add()">
        <img src="../../../../../../assets/icons/plus_2_icon.svg" class="me-1" alt="plus icon" />
        Add
      </button>
      <button class="instance-action-btn instance-action-btn--highlighted" (click)="edit()">
        <img src="../../../../../../assets/icons/pen_icon.svg" class="me-1" alt="edit icon" />
        Edit
      </button>
    </div>
    <div
      *ngIf="instanceViewMode() === InstanceViewMode.ADD || instanceViewMode() === InstanceViewMode.EDIT"
      class="section__edit d-flex justify-content-end"
    >
      <button id="section__cancel-btn" class="section__hover-btn-cancel" (click)="cancel()">Cancel</button>
      <button
        [disabled]="(areErrors() && submitted()) || areTouchedErrors() || pending()"
        id="section__save-btn"
        class="section__hover-btn"
        (click)="save()"
      >
        Save
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="researchObjectSignal().instances.length || instanceViewMode() !== InstanceViewMode.READ; else noInstances">
    <app-item-details-table-section
      [editMode]="instanceViewMode() === InstanceViewMode.EDIT || instanceViewMode() === InstanceViewMode.ADD"
      [fields]="instancesViewData"
      [errorMessage]="errorMessage$ | async"
      (errorsChange)="onErrorsChange($event)"
    ></app-item-details-table-section>
  </ng-container>

  <ng-template #noInstances>
    <app-empty-area-content
      (click)="add()"
      [developmentType]="DevelopmentType.researchObject"
      [sectionType]="SectionType.INSTANCES"
      [edit]="!!section().fields.length"
      [inactive]="!editable() || !section().fields.length"
      [label]="addInstanceLabel()"
    ></app-empty-area-content>
  </ng-template>
  <ng-template #removeRejectionModal let-modal>
    <app-item-details-edit-modal
      [project]="project()"
      [developmentType]="DevelopmentType.researchObject"
      [itemName]="activeInstance.serial_number"
      (closeModal)="modal.close()"
      [roId]="researchObjectSignal().id"
    >
      <app-item-details-remove-rejection
        [developmentTypeToDisplaySingular]="sectionName()"
        [developmentItemName]="activeInstance.serial_number"
        [noOfRelatedItems]="researchObjectSignal().experiments.length"
        [relatedDevelopmentTypeToDisplay]="project()?.development_type_names.experiments"
      ></app-item-details-remove-rejection>
    </app-item-details-edit-modal>
    <ng-container m-footer></ng-container>
  </ng-template>
  <ng-template #removeConfirmationModal let-modal>
    <app-item-details-info-modal
      [project]="project()"
      sectionName="delete"
      [categoryName]="researchObjectSignal().template.category.name"
      [developmentType]="DevelopmentType.instance"
      [instanceNameSingular]="section()?.name"
      [itemName]="activeInstance?.serial_number"
      (closeModal)="modal.close()"
      (editBtnClick)="modal.close()"
      (saveBtnClick)="delete(modal)"
    >
      <app-item-details-remove-confirmation
        [project]="project()"
        [developmentType]="DevelopmentType.instance"
        [instanceNameSingular]="section()?.name"
      ></app-item-details-remove-confirmation>
    </app-item-details-info-modal>
  </ng-template>
</app-item-details-section>
