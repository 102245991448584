import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-item-details-remove-rejection',
  standalone: true,
  templateUrl: './item-details-remove-rejection.component.html',
  styleUrls: ['./item-details-remove-rejection.component.scss']
})
export class ItemDetailsRemoveRejectionComponent {
  @Input() developmentTypeToDisplaySingular: string;
  @Input() developmentItemName: string;
  @Input() noOfRelatedItems: number;
  @Input() relatedDevelopmentTypeToDisplay: string;
}
