export interface IAuthenticationData {
  email: string;
  password: any;
}

export class LoginMethod {
  loginMethod: string;
}

export enum LoginMethodType {
  LOCAL = 'local',
  TEST = 'test',
  UAT = 'uat',
  PROD = 'prod',
  PROD_EXTERNAL = 'prodexternal',
  FB = 'fb'
}
