import { Component, computed, input, output } from '@angular/core';
import { trackByIndex } from '@app/shared/_helpers';
import { CategoryTemplateDto, Field, InstanceDto, ResearchObject } from '@app/shared/_models';
import { toField } from '@app/shared/dto-adapters/field';
import { getInstanceSectionName } from '@app/domain/research-object';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { selectTemplates } from '@shared/_root-store/category-templates-store/category-templates.selectors';
import { selectSelectedProject } from '@shared/_root-store/projects-store/projects.selectors';
import { getParameterFromTemplate } from '@app/_research_objects/research-object/related-experiments/utils/get-parameter-from-template';

interface InstanceVM extends InstanceDto {
  fields: Field[];
}

@Component({
  selector: 'app-research-object-table-item-details',
  templateUrl: './research-object-table-item-details.component.html',
  styleUrls: ['./research-object-table-item-details.component.scss']
})
export class ResearchObjectTableItemDetailsComponent {
  researchObject = input.required<ResearchObject>();
  detailsMessage = input.required<string>();
  showInstancesDetailsChanged = output<boolean>();
  templatesSignal = toSignal<CategoryTemplateDto[]>(this.store.select(selectTemplates));
  templateSignal = computed<CategoryTemplateDto>(() =>
    this.templatesSignal().find(template => template.id === this.researchObject().template.id)
  );
  instancesVMSignal = computed<InstanceVM[]>(() => {
    return this.researchObject().instances.map(instance => ({
      ...instance,
      fields: instance.field_values.map(value => toField(getParameterFromTemplate(this.templateSignal(), value.field_template_id), value))
    }));
  });
  firstInstanceFieldsSignal = computed<Field[]>(() => this.instancesVMSignal()[0]?.fields || []);
  project = toSignal(this.store.select(selectSelectedProject));
  instanceSectionName = computed<string>(() => getInstanceSectionName(this.researchObject(), this.project().section_type_names));

  showInstancesDetails = false;
  readonly trackByIndex = trackByIndex;

  constructor(private store: Store) {}

  switchShowInstancesDetails(showInstancesDetails: boolean) {
    this.showInstancesDetails = showInstancesDetails;
    this.showInstancesDetailsChanged.emit(this.showInstancesDetails);
  }
}
