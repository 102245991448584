<ng-container *ngIf="!(signedUpUser$ | async); else successTmpl">
  <form id="signup-form" [formGroup]="signupForm" (submit)="signUp($event)">
    <div
      *ngIf="signupForm.get(SIGNUP_FORM_KEYS.NAME) as suFirstName"
      class="form-group"
      [ngClass]="{ focused: focusFirstName(), 'is-invalid': suFirstName.invalid && (formSubmitted$ | async) }"
    >
      <label class="text-uppercase">First Name</label>
      <div class="input-wrapper">
        <i class="far fa-user"></i>
        <input
          type="text"
          class="form-control"
          [formControlName]="SIGNUP_FORM_KEYS.NAME"
          placeholder="First Name"
          (focusin)="focusFirstName.set(true)"
          data-testid="first-name-input"
          (focusout)="focusFirstName.set(false)"
        />
      </div>
      <ng-container *ngIf="(formSubmitted$ | async) && suFirstName.hasError('required')">
        <small data-testid="first-name-required-validation-error" class="form-text invalid-feedback d-block">First Name is required</small>
      </ng-container>
      <ng-container *ngIf="(formSubmitted$ | async) && suFirstName.hasError('meetsConditions')">
        <small data-testid="first-name-letters-only-validation-error" class="form-text invalid-feedback d-block">
          First Name should consist of letters only
        </small>
      </ng-container>
    </div>
    <div
      *ngIf="signupForm.get(SIGNUP_FORM_KEYS.SURNAME) as suLastName"
      class="form-group mt-3"
      [ngClass]="{ focused: focusLastName(), 'is-invalid': suLastName.invalid && (formSubmitted$ | async) }"
    >
      <label class="text-uppercase">Last Name</label>
      <div class="input-wrapper">
        <i class="far fa-user"></i>
        <input
          type="text"
          class="form-control"
          [formControlName]="SIGNUP_FORM_KEYS.SURNAME"
          placeholder="Last Name"
          (focusin)="focusLastName.set(true)"
          data-testid="last-name-input"
          (focusout)="focusLastName.set(false)"
        />
      </div>
      <ng-container *ngIf="(formSubmitted$ | async) && suLastName.hasError('required')">
        <small data-testid="last-name-required-validation-error" class="form-text invalid-feedback d-block">Last Name is required</small>
      </ng-container>
      <ng-container *ngIf="(formSubmitted$ | async) && suLastName.hasError('meetsConditions')">
        <small data-testid="last-name-letters-only-validation-error" class="form-text invalid-feedback d-block">
          Last Name should consist of letters only
        </small>
      </ng-container>
    </div>
    <div
      *ngIf="signupForm.get(SIGNUP_FORM_KEYS.EMAIL) as suEmail"
      class="form-group mt-3"
      [ngClass]="{ focused: focusEmail(), 'is-invalid': suEmail.invalid && (formSubmitted$ | async) }"
    >
      <label class="text-uppercase">Email</label>
      <div class="input-wrapper">
        <i class="far fa-envelope"></i>
        <input
          type="email"
          class="form-control"
          [formControlName]="SIGNUP_FORM_KEYS.EMAIL"
          placeholder="Email"
          (focusin)="focusEmail.set(true)"
          data-testid="email-input"
          (focusout)="focusEmail.set(false)"
        />
      </div>
      <ng-container *ngIf="(formSubmitted$ | async) && suEmail.hasError('required')">
        <small data-testid="email-required-validation-error" class="form-text invalid-feedback d-block">Email is required</small>
      </ng-container>
      <ng-container *ngIf="(formSubmitted$ | async) && suEmail.hasError('invalidEmail')">
        <small data-testid="inproper-email-format-validation-error" class="form-text invalid-feedback d-block">Inproper email format</small>
      </ng-container>
    </div>
    <div
      *ngIf="signupForm.get(SIGNUP_FORM_KEYS.PASSWORD) as suPassword"
      class="form-group mt-3"
      [ngClass]="{ focused: focusPassword(), 'is-invalid': suPassword.invalid && (formSubmitted$ | async) }"
    >
      <label class="text-uppercase">Password</label>
      <div class="input-wrapper">
        <i class="fas fa-lock"></i>
        <input
          type="password"
          class="form-control"
          [formControlName]="SIGNUP_FORM_KEYS.PASSWORD"
          placeholder="Password"
          (focusin)="focusPassword.set(true)"
          data-testid="password-input"
          (focusout)="focusPassword.set(false)"
        />
      </div>
      <ng-container *ngIf="hasPasswordConditionsError$ | async">
        <small data-testid="password-security-validation-error" class="form-text invalid-feedback d-block">
          The password shall contain at least 8 characters, one number and upper case character.
        </small>
      </ng-container>
      <ng-container *ngIf="(formSubmitted$ | async) && suPassword.hasError('required')">
        <small data-testid="password-required-validation-error" class="form-text invalid-feedback d-block">Password is required</small>
      </ng-container>
      <app-password-strength-meter
        [password]="signupForm.get(SIGNUP_FORM_KEYS.PASSWORD).value"
        [minPasswordLength]="8"
        [numberOfProgressBarItems]="4"
      ></app-password-strength-meter>
      <small data-testid="password-requirements-info" *ngIf="!(hasPasswordConditionsError$ | async)">
        The password shall contain at least 8 characters, one number and upper case character.
      </small>
    </div>
    <div class="agreement-and-policy-container">
      I agree to the
      <a data-testid="agreement-link" href="#" (click)="showUserAgreement($event)">User Agreement</a>
      and
      <a data-testid="policy-link" href="#" (click)="showPrivacyPolicy($event)">Privacy Policy</a>
      .
    </div>
    <div class="form-group mt-4">
      <app-button class="general-primary w-100 h-45">Sign Up</app-button>
    </div>
    <div class="row additional-text">
      <div class="col">
        Already have an account?
        <a data-testid="go-to-sign-in-button" [routerLink]="signInRoutingPath">Sign In</a>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #successTmpl>
  <div data-testid="sign-up-confirmation-text" class="confirmation-text" *ngIf="signedUpUser$ | async as signedUpUser">
    <h2>Account Activation</h2>
    <p>
      Dear {{ signedUpUser.name }} {{ signedUpUser.surname }},
      <br />
      <br />
      Your account has to be activated in order to use LabGears.
    </p>
    <p>An activation link was sent on the email provided during Sign Up process.</p>
    <p>
      In case of any questions, please contact administrator:
      <br />
      <a [href]="'mailto:' + CONTACT_ADMINISTRATOR">{{ CONTACT_ADMINISTRATOR }}</a>
    </p>
  </div>
</ng-template>

<ng-template #userAgreementModal let-modal>
  <app-generic-modal [hideHeaderCloseIcon]="true" [hideFooter]="true" (closeModal)="modal.close()" class="user-agreement">
    <ng-template #headerTemplate>
      <ng-template [ngTemplateOutlet]="modalHeaderTemplate"></ng-template>
    </ng-template>
    <app-user-agreement />
  </app-generic-modal>
</ng-template>

<ng-template #privacyPolicyModal let-modal>
  <app-generic-modal [hideHeaderCloseIcon]="true" [hideFooter]="true" (closeModal)="modal.close()" class="privacy-policy">
    <ng-template #headerTemplate>
      <ng-template [ngTemplateOutlet]="modalHeaderTemplate"></ng-template>
    </ng-template>
    <app-privacy-policy />
  </app-generic-modal>
</ng-template>

<ng-template #modalHeaderTemplate>
  <div class="header-container">
    <span class="signup__modal-header">Lab Gears</span>
    <app-icon
      class="header-close"
      width="1rem"
      height="1rem"
      fileName="close_icon.svg"
      [color]="IconColors.darkGrey"
      (click)="closeModal()"
      data-testid="close-header-button"
    ></app-icon>
  </div>
</ng-template>
