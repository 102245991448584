import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

import { isPreprocessedError } from './is-prepocessed-error';
import { PreprocessedErrorDto, PreprocessedErrorItemDto } from '@shared/_models/error-message';

export function addErrorToForm(errorResponse: HttpErrorResponse, form: FormGroup) {
  if (!isPreprocessedError(errorResponse?.error)) throw errorResponse;

  const handleValidationError = (error: PreprocessedErrorItemDto) => {
    const control = form.get(error.location);

    if (control) {
      control.setErrors({ backendError: error.message });
    } else {
      throw errorResponse;
    }
  };

  const preprocessedError: PreprocessedErrorDto = errorResponse.error;

  preprocessedError.errors.forEach(handleValidationError);
}
