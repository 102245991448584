import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { navigateToDefaultRoute } from '@shared/_root-store/app-store/app.actions';

import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class IsNotAuthenticatedGuard {
  constructor(
    private readonly store: Store,
    private readonly authService: AuthService
  ) {}

  canActivate(): boolean {
    const isAuthenticated = this.authService.isAuthenticated();

    if (!isAuthenticated) {
      return true;
    }

    this.store.dispatch(navigateToDefaultRoute());

    return false;
  }
}
