import { DevelopmentType, ProjectDto, SectionType, AreaName } from '@shared/_models';

export function getAreaName(project: ProjectDto, developmentType: DevelopmentType): AreaName {
  if (project && developmentType === DevelopmentType.researchObject)
    return {
      [SectionType.IMAGES]: project.section_type_names.ro_images,
      [SectionType.PARAMETERS]: project.section_type_names.ro_parameters,
      [SectionType.INSTANCES]: project.section_type_names.ro_samples,
      [SectionType.RESULTS]: undefined
    };

  if (project && developmentType === DevelopmentType.experiment)
    return {
      [SectionType.IMAGES]: project.section_type_names.exp_images,
      [SectionType.RESULTS]: project.section_type_names.exp_results,
      [SectionType.PARAMETERS]: project.section_type_names.exp_parameters,
      [SectionType.INSTANCES]: undefined
    };

  return {
    [SectionType.IMAGES]: undefined,
    [SectionType.RESULTS]: undefined,
    [SectionType.PARAMETERS]: undefined,
    [SectionType.INSTANCES]: undefined
  };
}
