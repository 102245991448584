import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, input, output, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NgbModal, NgbModalRef, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { CloseReason } from '@app/shared/_components/generic-modal/utils';
import { IconColors } from '@app/shared/_components/icon/utils/icon-colors';
import { AppSettings } from '@app/shared/_configuration';
import { DevelopmentType, RouteParam, DevelopmentItemLinkedFilesShortDto, UpdateArea } from '@app/shared/_models';
import { selectRouteParam } from '@app/shared/_root-store/router-store/selectors';
import { NavigationService } from '@app/shared/_services/navigation.service';

import { GenericModalComponent } from '../../../_components/generic-modal/generic-modal.component';
import { IconComponent } from '../../../_components/icon/components/icon/icon.component';
import { ModalHeaderCenterComponent } from '../../../_components/modal-header-center/modal-header-center.component';
import { FileWithoutExtensionPipe } from '../../../pipes/file-without-extension.pipe';

@Component({
  selector: 'app-file-sharing-development-item',
  standalone: true,
  imports: [IconComponent, GenericModalComponent, ModalHeaderCenterComponent, NgTemplateOutlet, FileWithoutExtensionPipe, NgbTooltip],
  templateUrl: './file-sharing-development-item.component.html',
  styleUrls: ['./file-sharing-development-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileSharingDevelopmentItemComponent {
  developmentItem = input<DevelopmentItemLinkedFilesShortDto>();
  developmentItemsLength = input<number>();
  fileId = input<string>();
  fileName = input<string>();
  index = input<number>();
  unlink = output<void>();

  projectId = toSignal(this.store.select(selectRouteParam(RouteParam.PROJECT_ID)));
  workspaceId = toSignal(this.store.select(selectRouteParam(RouteParam.WORKSPACE_ID)));
  categoryIdFromUrl = toSignal(this.store.select(selectRouteParam(RouteParam.CATEGORY_ID)));

  @ViewChild('unlinkDevelopmentTypeConfirmationModal', { static: false }) unlinkDevelopmentTypeConfirmationModal: ElementRef;
  unlinkDevelopmentTypeConfirmationModalRef: NgbModalRef;

  IconColors = IconColors;
  DevelopmentType = DevelopmentType;
  CloseReason = CloseReason;
  UpdateArea = UpdateArea;

  constructor(
    @Inject(AppSettings) public readonly settings: AppSettings,
    private readonly navigationService: NavigationService,
    private readonly ngbModalService: NgbModal,
    private readonly store: Store
  ) {}

  openUnlinkDevelopmentTypeConfirmationModal() {
    this.unlinkDevelopmentTypeConfirmationModalRef = this.ngbModalService.open(
      this.unlinkDevelopmentTypeConfirmationModal,
      this.settings.MODAL_DEFAULT_CONFIG
    );
  }

  close(reason: CloseReason) {
    if (reason === CloseReason.CLOSE) {
      this.unlinkDevelopmentTypeConfirmationModalRef.close();
      return;
    }

    // ACCEPT
    this.removeDevelopmentItem();
    this.unlinkDevelopmentTypeConfirmationModalRef.close();
  }

  removeDevelopmentItem() {
    this.unlink.emit();
  }

  goToDevelopmentItemFiles() {
    if (this.developmentItem().development_type === DevelopmentType.experiment) {
      this.navigationService.goToExperimentFiles(
        this.workspaceId(),
        this.projectId(),
        this.developmentItem().id,
        this.categoryIdFromUrl(),
        true
      );
    } else {
      this.navigationService.goToResearchObjectFiles(
        this.workspaceId(),
        this.projectId(),
        this.developmentItem().id,
        this.categoryIdFromUrl(),
        true
      );
    }
  }
}
