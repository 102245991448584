import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, computed, input, output } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { InstanceDto, ResearchObject, SectionType } from '@shared/_models';
import { ItemsCounterComponent } from '../../items-counter/items-counter.component';

@Component({
  selector: 'app-item-details-instances-dropdown',
  standalone: true,
  imports: [NgbDropdownModule, ItemsCounterComponent, NgClass, NgIf, NgFor],
  templateUrl: './item-details-instances-dropdown.component.html',
  styleUrls: ['./item-details-instances-dropdown.component.scss']
})
export class ItemDetailsInstancesDropdownComponent {
  researchObject = input.required<ResearchObject>();
  activeInstance = input.required<InstanceDto>();
  areaName = input.required<string>();
  onActiveInstanceChange = output<InstanceDto>();

  instancesDropownOpened = false;

  instancesSerialNumbers = computed<string[]>(() => this.researchObject()?.instances.map(x => x.serial_number) ?? []);

  sectionName = computed<string>(() => {
    const section = this.researchObject()?.template?.sections?.find(section => section.type === SectionType.INSTANCES);

    return section?.name || this.areaName();
  });
}
