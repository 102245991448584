<div>
  <textarea
    rows="1"
    class="dynamic-field-input"
    [placeholder]="placeholder"
    [ngModel]="ngModel()"
    (keydown)="onKeydown($event)"
    (focus)="setHeightToContent($event.target)"
    (input)="setHeightToContent($event.target)"
    (ngModelChange)="onNgModelChange($event)"
    (blur)="blurred.emit()"
  ></textarea>
  <app-text-counter
    *ngIf="counterOptions?.show"
    [length]="ngModel()?.length || 0"
    [maxLength]="counterOptions.maxLength"
  ></app-text-counter>
</div>
