<app-generic-modal
  [closeButton]="closeButton"
  [acceptButton]="acceptButton"
  [developmentType]="developmentType"
  [primary]="true"
  [hideHeaderCloseIcon]="true"
  [hideFooter]="false"
  class="item-details-info"
  (closeModal)="close($event)"
>
  <ng-template #headerTemplate>
    <ng-template [ngTemplateOutlet]="modalHeaderTemplate"></ng-template>
  </ng-template>
  <ng-template *ngIf="itemName" #footerTemplate>
    <div class="info-modal__actions w-100">
      <app-button class="experiment-tertiary w-100 h-45 mr-8" (click)="showRelatedItems()">List of {{ listOfButtonName }} used</app-button>
      <app-button class="research-object-primary w-100 h-45 ml-8" (click)="close(CloseReason.CLOSE)">Close</app-button>
    </div>
  </ng-template>
  <div class="info-modal__body">
    <ng-content></ng-content>
    <div data-testid="modal-body-message" class="info-modal__body-message">{{ message }}</div>
  </div>
</app-generic-modal>

<ng-template #modalHeaderTemplate>
  <app-modal-header-center [developmentType]="developmentType" [imgSrc]="imgSrc" [header]="header"></app-modal-header-center>
</ng-template>
