<app-generic-modal
  data-testid="project-action-modal"
  [closeButton]="{ text: cancelButtonLabel }"
  [acceptButton]="{ text: actionButtonLabel }"
  [developmentType]="DevelopmentType.project"
  [primary]="true"
  [hideHeaderCloseIcon]="true"
  (closeModal)="close($event)"
>
  <ng-template #headerTemplate>
    <ng-template [ngTemplateOutlet]="modalCenterHeaderTemplate"></ng-template>
  </ng-template>
  <div class="info-modal__body">
    <div class="info-modal__body-content">
      <span class="info-modal__body-label">{{ bodyPreLabel }}&nbsp;</span>
      <span class="info-modal__body-project-label">{{ projectName }}</span>
      <span class="info-modal__body-label" [ngClass]="{ 'info-modal__body-project-label': this.actionType === ACTION_TYPE.DELETE }">
        {{ bodyPostLabel }}
      </span>
      <div class="info-modal__body-label">{{ bodyLabelQuestion }}</div>
    </div>
  </div>
</app-generic-modal>

<ng-template #modalCenterHeaderTemplate let-modal>
  <app-modal-header-center
    [imgSrc]="this.actionType !== ACTION_TYPE.JOIN ? 'toast_icon_warning.png' : 'project_icon_with_bg.svg'"
    [header]="bodyHeader"
  ></app-modal-header-center>
</ng-template>
