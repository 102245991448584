import { NgFor } from '@angular/common';
import { Component, ChangeDetectionStrategy, input, output, computed } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DropdownOption } from './utils';

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [NgFor, NgbModule],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent {
  items = input.required<DropdownOption[]>();
  selectedValue = input<string>();
  selected = output<string>();

  selectedLabel = computed<string>(() => this.items().find(item => item.value === this.selectedValue())?.label);
  isDropdownOpened = false;

  onSelect(item: DropdownOption) {
    if (item.disabled) return;

    this.selected.emit(item.value);
  }
}
