import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { isNil as _isNil } from 'lodash-es';

import { StorageService } from '../shared/_services/storage/storage.service';
import { AuthApiService } from './api/auth-api.service';
import { STORAGE_TOKEN_KEY } from './constants';
import { AuthRoutingPath } from './auth-routing-path';
import { toMessage } from '@app/domain/error-handling';

import {
  AuthRemindPasswordRequest,
  AuthResetPasswordRequest,
  AuthSignInMicrosoftResponse,
  AuthSignInResponse,
  AuthSignUpRequest,
  AuthSignUpResponse,
  AuthVerifyUserRequest
} from './interface';
import { ToastService } from '@shared/_modules/toast/toast.service';
import { SignInRoutingPath } from '@app/_auth/signin/signin-routing-path';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly signInRoutingPath = [SignInRoutingPath.signIn];
  readonly requestResetPasswordRoutingPath = [AuthRoutingPath.auth, AuthRoutingPath.password];

  constructor(
    private readonly router: Router,
    private readonly storageService: StorageService,
    private readonly authApiService: AuthApiService,
    private readonly toastService: ToastService
  ) {}

  isAuthenticated(): boolean {
    const token = this.storageService.getToken();

    return !_isNil(token);
  }

  redirectToSignIn(extras?: NavigationExtras): void {
    void this.router.navigate(this.signInRoutingPath, extras);
  }

  redirectToInitialPage(workspaceId: string, returnUrl?: string): void {
    if (returnUrl) {
      this.router.navigateByUrl(returnUrl);

      return;
    }

    void this.router.navigate(['', 'app', workspaceId, 'projects']);
  }

  redirectToPasswordChangeRequest(): void {
    void this.router.navigate(this.requestResetPasswordRoutingPath);
  }

  signIn(requestData: Record<string, string>): Observable<AuthSignInResponse> {
    return this.authApiService.signIn(requestData).pipe(
      switchMap((response: AuthSignInResponse) => {
        const { [STORAGE_TOKEN_KEY]: token } = response;

        this.storageService.setToken(token);

        return of(response);
      })
    );
  }

  signInWithMicrosoft(): Observable<AuthSignInMicrosoftResponse> {
    return this.authApiService.signInWithMicrosoft();
  }

  callBackMicrosoft(code: string, state: string): Observable<AuthSignInResponse> {
    return this.authApiService.callBackMicrosoft(code, state).pipe(
      switchMap((response: AuthSignInResponse) => {
        const { [STORAGE_TOKEN_KEY]: token } = response;

        this.storageService.setToken(token);

        return of(response);
      })
    );
  }

  signOut(): void {
    this.storageService.unsetToken();
    this.redirectToSignIn();
  }

  signUp(requestData: AuthSignUpRequest): Observable<AuthSignUpResponse> {
    return this.authApiService.signUp(requestData).pipe(
      catchError(errorResponse => {
        if (errorResponse.status === 400) {
          this.toastService.show(toMessage(errorResponse), {
            header: 'Failed to create account',
            type: 'danger'
          });

          return EMPTY;
        }

        throwError(() => errorResponse);
      }),
      switchMap(response => this.authApiService.verifyToken({ email: response.coalesced_email }).pipe(map(() => response)))
    );
  }

  verifyUser(requestData: AuthVerifyUserRequest): Observable<void> {
    return this.authApiService.verifyUser(requestData);
  }

  requestResetPassword(requestData: AuthRemindPasswordRequest): Observable<void> {
    return this.authApiService.remindPassword(requestData);
  }

  resetPassword(requestData: AuthResetPasswordRequest): Observable<any> {
    return this.authApiService.resetPassword(requestData);
  }

  loginMethod(): Observable<string> {
    return this.authApiService.loginMethod().pipe(map(response => response.loginMethod));
  }
}
