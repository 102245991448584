// check if HttpErrorResponse.error is of type PreprocessedErrorDto
export function isPreprocessedError(error: any): boolean {
  if (!error) return false;

  if (!(Array.isArray(error.errors) && error.errors.length > 0)) return false;

  const [firstError] = error.errors;

  return !!firstError.message;
}
