import { ResearchObject, SectionType, SectionTypeNamesDto } from '@shared/_models';

export function getInstanceSectionName(researchObject: ResearchObject, sectionTypeNames: SectionTypeNamesDto): string {
  const template = researchObject?.template;

  if (!template) return;

  const active = template.active_section_types?.includes(SectionType.INSTANCES);

  if (active) return template?.sections?.find(section => section.type === SectionType.INSTANCES)?.name;

  return sectionTypeNames?.ro_samples;
}
