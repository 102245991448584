<app-input-with-validation
  [control]="control()"
  [config]="config()"
  [backendErrorMessage]="backendErrorMessage()"
  externalTouched="externalTouched()"
>
  <div class="form-select-input" [ngClass]="{ 'dynamic-field-border': config()?.isGridView }">
    <div class="form-select-input__content">
      <app-dropdown-select
        [items]="options()"
        [colorsEnabled]="hasColor()"
        [formControl]="control()"
        [placeholder]="config()?.placeholder || DEFAULT_SELECT_PLACEHOLDER"
        [clearable]="true"
        (blured)="blur$.next()"
      ></app-dropdown-select>
    </div>
  </div>
</app-input-with-validation>
