import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { MemberRole, ProjectDto, UpdateProjectDto } from '@shared/_models';

export const loadProjects = createAction('[Projects] Load Projects', props<{ workspaceId: string }>());
export const loadProjectsSuccess = createAction('[Projects] Load Projects Success', props<{ data: ProjectDto[] }>());
export const loadProjectsFailure = createAction('[Projects] Load Projects Failure', props<{ errorResponse: HttpErrorResponse }>());
export const selectProject = createAction('[Projects] Select Project', props<{ project: ProjectDto }>());
export const updateProject = createAction(
  '[Projects] Update Project',
  props<{ projectId: string; changes: Partial<UpdateProjectDto>; onSuccess?: () => void }>()
);
export const updateProjectSuccess = createAction('[Projects] Update Project Success', props<{ data: ProjectDto }>());
export const updateProjectFailure = createAction('[Projects] Update Project Failure', props<{ errorResponse: HttpErrorResponse }>());
export const resetError = createAction('[Projects] Reset Error');
export const addMemberToProject = createAction('[Projects] Add member to project', props<{ userId: string; projectId: string }>());
export const removeUserFromProject = createAction('[Projects] Remove user from project', props<{ userId: string; projectId: string }>());
export const changeRole = createAction('[Projects] Change role', props<{ userId: string; projectId: string; newRole: MemberRole }>());
