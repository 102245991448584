import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { CategoryDto, UpdateCategoryDto } from '@shared/_models';

export const loadCategories = createAction('[Categories] Load Categories');
export const loadCategoriesSuccess = createAction('[Categories] Load Categories Success', props<{ data: CategoryDto[] }>());
export const loadCategoriesFailure = createAction('[Categories] Load Categories Failure', props<{ error: HttpErrorResponse }>());

export const addCategory = createAction('[Categories] Add Category', props<{ category: CategoryDto; onSuccessAction: () => void }>());
export const addCategorySuccess = createAction('[Categories] Add Category Success', props<{ category: CategoryDto }>());
export const addCategoryFailure = createAction('[Categories] Add Category Failure', props<{ error: HttpErrorResponse }>());

export const deleteCategory = createAction('[Categories] Delete Category', props<{ categoryId: string }>());
export const deleteCategorySuccess = createAction('[Categories] Delete Category Success', props<{ categoryId: string }>());
export const deleteCategoryFailure = createAction('[Categories] Delete Category Failure', props<{ errorResponse: HttpErrorResponse }>());

export const updateCategory = createAction(
  '[Categories] Update Category',
  props<{ categoryId: string; update: UpdateCategoryDto; onSuccessAction?: () => void; changeCategoryOrder?: boolean }>()
);
export const updateCategorySuccess = createAction('[Categories] Update Category Success', props<{ category: CategoryDto }>());
export const updateCategoryFailure = createAction('[Categories] Update Category Failure', props<{ errorResponse: HttpErrorResponse }>());

export const duplicateCategory = createAction('[Categories] Duplicate Category', props<{ categoryId: string; name: string }>());
export const duplicateCategorySuccess = createAction('[Categories] Duplicate Category Success', props<{ category: CategoryDto }>());
export const duplicateCategoryFailure = createAction('[Categories] Duplicate Category Failure', props<{ error: HttpErrorResponse }>());

export const changeCategoryOrder = createAction(
  '[Categories] Change category order',
  props<{ categoryId: string; update: UpdateCategoryDto; onFailureAction?: () => void }>()
);
export const changeCategoryOrderSuccess = createAction('[Categories] Change category order success', props<{ category: CategoryDto }>());

export const clearErrorMessage = createAction('[Categories] Clear Error Message');
