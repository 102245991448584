import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, Type, ViewContainerRef } from '@angular/core';

import { GridItemSkeletonComponent } from './grid-item-skeleton/grid-item-skeleton.component';
import { TableItemSkeletonComponent } from './table-item-skeleton/table-item-skeleton.component';
import { SkeletonType } from '../../_models';
import { ItemDetailsSkeletonComponent } from './item-details-skeleton/item-details-skeleton.component';

@Directive({
  selector: '[appSkeleton]',
  standalone: true
})
export class SkeletonDirective implements OnChanges {
  @Input('appSkeleton') isLoading = true;
  @Input('appSkeletonType') type: SkeletonType = SkeletonType.GRID_ITEM;
  @Input() appSkeletonRepeat = 1;
  @Input('appSkeletonClassName') className: string;

  constructor(
    private readonly tpl: TemplateRef<any>,
    private readonly vcr: ViewContainerRef
  ) {}

  private get skeletonComponent(): Type<GridItemSkeletonComponent> | Type<TableItemSkeletonComponent> | Type<ItemDetailsSkeletonComponent> {
    const skeletonComponents = {
      [SkeletonType.GRID_ITEM]: GridItemSkeletonComponent,
      [SkeletonType.TABLE_ITEM]: TableItemSkeletonComponent,
      [SkeletonType.ITEM_DETAILS]: ItemDetailsSkeletonComponent
    };

    return skeletonComponents[this.type];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading) {
      this.vcr.clear();

      if (changes.isLoading.currentValue) {
        Array.from({ length: this.appSkeletonRepeat }).forEach(() => {
          const ref = this.vcr.createComponent(this.skeletonComponent);

          Object.assign(ref.instance, {
            className: this.className
          });
        });
      } else {
        this.vcr.createEmbeddedView(this.tpl);
      }
    }
  }
}
