import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { Component, computed, input, output, signal, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { provideComponentStore } from '@ngrx/component-store';
import { EMPTY } from 'rxjs';

import { getShortGUID } from '@shared/_helpers';
import { DevelopmentType, LinkedFileDto, DevelopmentItemLinkedFilesShortDto } from '@shared/_models';
import { FileApiService } from '@shared/_services/file';

import { FileSharingModalComponentStore } from './file-sharing-modal.store';
import { GenericModalComponent } from '../../../_components/generic-modal/generic-modal.component';
import { ModalHeaderLeftComponent } from '../../../_components/modal-header-left/modal-header-left.component';
import { FileWithoutExtensionPipe } from '../../../pipes/file-without-extension.pipe';
import { FileSharingDevelopmentItemsComponent } from '../file-sharing-development-items/file-sharing-development-items.component';
import { FileUploadCardComponent } from '../file-upload-card/file-upload-card.component';
import { FileUploadStatus } from '../models/file-upload-status';

@Component({
  selector: 'app-file-sharing-modal',
  standalone: true,
  imports: [
    GenericModalComponent,
    ModalHeaderLeftComponent,
    NgTemplateOutlet,
    CommonModule,
    FileWithoutExtensionPipe,
    FileUploadCardComponent,
    FileSharingDevelopmentItemsComponent
  ],
  templateUrl: './file-sharing-modal.component.html',
  styleUrls: ['./file-sharing-modal.component.scss'],
  providers: [provideComponentStore(FileSharingModalComponentStore)],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileSharingModalComponent implements OnInit {
  file = input<LinkedFileDto>();
  developmentType = input<DevelopmentType>();
  developmentItemId = input<string>();
  closeModal = output<void>();
  loadFiles = output<void>();
  onLastUnlinked = output<void>();

  storeFile$ = this.componentStore.file$;
  linkedToDevelopmentItems$ = this.componentStore.linkedToDevelopmentItems$;
  file$ = toObservable(this.file);
  fileUploadStatus = signal<FileUploadStatus>({
    guid: null,
    fileToUpload: null,
    uploadPending: false,
    uploadHttpEvents$: EMPTY
  });
  fileName = computed<string>(() => this.fileUploadStatus().fileToUpload?.name ?? this.file().name);
  fileSize = computed<number>(() => this.fileUploadStatus().fileToUpload?.size ?? this.file().size);
  fileExtension = computed<string>(() => this.fileUploadStatus().fileToUpload?.type ?? this.file().type);

  DevelopmentType = DevelopmentType;
  developmentItems: DevelopmentItemLinkedFilesShortDto[];
  developmentItemsSearchValues: DevelopmentItemLinkedFilesShortDto[] = [];

  constructor(
    private readonly fileApiService: FileApiService,
    readonly componentStore: FileSharingModalComponentStore
  ) {
    this.#syncStore();
  }

  ngOnInit() {
    this.componentStore.setup(this.file(), () => this.onLastUnlinked.emit());
    this.componentStore.loadLinkedToDevelopmentItems();
  }

  onFileSelected(file: File) {
    const updatedStatus = this.#createFileUploadStatusFromFile(file);
    this.fileUploadStatus.update(value => ({
      ...value,
      ...updatedStatus
    }));
  }

  onUploadInProgress() {
    this.fileUploadStatus.update(value => ({
      ...value,
      uploadPending: true
    }));
  }

  onUploadSuccess() {
    this.fileUploadStatus.update(value => ({
      ...value,
      uploadPending: false
    }));
    this.loadFiles.emit();
  }

  onUploadFail() {
    this.fileUploadStatus.update(value => ({
      ...value,
      uploadPending: false
    }));
  }

  onLink(developmentItem: DevelopmentItemLinkedFilesShortDto) {
    this.componentStore.link(developmentItem);
  }

  onUnlink(developmentItem: DevelopmentItemLinkedFilesShortDto) {
    this.componentStore.unlink(developmentItem);
  }

  #createFileUploadStatusFromFile(file: File): FileUploadStatus {
    const formData = new FormData();
    formData.append('file', file);

    const uploadHttpEvents$ = this.fileApiService.replace(formData, this.file().id);
    return {
      guid: getShortGUID(),
      fileToUpload: file,
      uploadPending: false,
      uploadHttpEvents$
    };
  }

  #syncStore() {
    this.file$.pipe(takeUntilDestroyed()).subscribe(file => {
      this.componentStore.setup(file);
    });
  }
}
