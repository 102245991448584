<ng-template #itemsRemoveConfirmationModal let-modal>
  <app-generic-modal
    [closeButton]="{ text: 'Close' }"
    [acceptButton]="{ text: 'Remove' }"
    [developmentType]="developmentType"
    [primary]="true"
    [hideHeaderCloseIcon]="true"
    (closeModal)="close($event)"
  >
    <ng-template #headerTemplate>
      <ng-template [ngTemplateOutlet]="modalCenterHeaderTemplate"></ng-template>
    </ng-template>
    <div class="info-modal__body">
      <div class="info-modal__body-content">
        Are you sure you want to remove&nbsp;
        <span class="color-blue">{{ selectedItemsLength }} selected {{ developmentTypeName }}</span>
        ?
      </div>
      <div class="text-danger">This operation can not be undone!</div>
    </div>
  </app-generic-modal>
</ng-template>

<ng-template #modalCenterHeaderTemplate>
  <app-modal-header-center
    [imgSrc]="'toast_icon_warning.png'"
    [header]="'Delete selected ' + developmentTypeName + '?'"
  ></app-modal-header-center>
</ng-template>

<ng-template #researchObjectsRemoveRejectionModal let-modal>
  <app-research-objects-remove-rejection
    [project]="project()"
    [modal]="modal"
    [deleteFailRoIds]="deleteFailRoIds"
    [deleteFailRelatedExpCount]="deleteFailRelatedExpCount"
  ></app-research-objects-remove-rejection>
</ng-template>
