<header>
  <h1>REGULAMIN</h1>
  <small>Last Update June 24th, 2024</small>
</header>
<article>
  <h3 appScrollSection="general-provisions">Postanowienia Ogólne</h3>
  <ol>
    <li>Niniejszy Regulamin określa zasady funkcjonowania oraz warunki korzystania z serwisu internetowego LabGears (dalej „Serwis”).</li>
    <li>
      Serwis internetowy LabGears ma charakter platformy umożliwiającej zarządzanie i współdzielenie przez Użytkowników danych badawczych i
      naukowych (dalej „Serwis”).
    </li>
    <li>
      Właścicielem Serwisu jest Adamed Pharma S.A. z siedzibą w Pieńkowie, ul. Mariana Adamkiewicza 6A, 05-152 Czosnów, wpisana do rejestru
      przedsiębiorców Krajowego Rejestru Sądowego pod numerem KRS 0000116926, przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIV Wydział
      Gospodarczy KRS, posiadająca NIP: 731-17-51-025 oraz REGON: 472293752 (dalej „Usługodawca”).
    </li>
  </ol>

  <h3 appScrollSection="rules-of-using-the-website">Zasady korzystania z Serwisu</h3>
  <ol>
    <li>
      Za pośrednictwem Serwisu Użytkownik uzyskuje możliwość wprowadzania, i przeglądania danych w postaci tekstu, plików i innych
      materiałów (łącznie „Treści Użytkownika”) oraz udostępnianie tych Treści Użytkownika innym osobom (łącznie „Usługa”).
    </li>
    <li>
      W celu założenia konta w Serwisie Użytkownik wprowadza swoje dane do formularza oraz akceptuje Politykę Prywatności Serwisu oraz
      niniejszy Regulamin.
    </li>
    <li>Korzystanie z Serwisu jest nieodpłatne i dobrowolne. Użytkownik ma prawo zrezygnować z korzystania z Serwisu w każdym czasie.</li>
    <li>Użytkownik zachowują prawo własności do Treści Użytkownika, które przesyła do Usługi.</li>
    <li>
      Przesyłając Treści Użytkownika Użytkownik udziela Usługodawcy licencji na dostęp, użytkowanie, kopiowanie, powielanie, przetwarzanie,
      adaptowanie, publikowanie, przesyłanie i wyświetlanie tych Treści Użytkownika w celu świadczenia Usługi, w tym jeśli jest to wymagane
      przez prawo lub w dobrej wierze w celu zachowania zgodności z procesem prawnym.
    </li>
    <li>
      Usługodawca zastrzega sobie prawo do usunięcia wszelkich Treści Użytkownika w Usłudze, które naruszają postanowienia niniejszego
      Regulaminu, prawo, są sprzeczne z zasadami współżycia społecznego lub narażają Usługodawcę na szkodę, utratę reputacji lub
      konsekwencje prawne.
    </li>
    <li>
      Usługodawca zastrzega sobie prawo do modyfikowania lub zaprzestania, tymczasowo lub na stałe, świadczenia Usługi i korzystania z
      Serwisu (lub dowolnej jego części) za powiadomieniem lub bez niego. Użytkownik przyjmuje do wiadomości, że Usługodawca nie ponosi
      odpowiedzialności wobec niego ani żadnej osoby trzeciej za jakiekolwiek modyfikacje, zawieszenie lub zaprzestanie korzystania z Usługi
      i Serwisu.
    </li>
  </ol>

  <h3 appScrollSection="complaints-procedure">Postępowanie reklamacyjne</h3>
  <ol>
    <li>
      Reklamacje składane w związku z korzystaniem z Serwisu Użytkownik może zgłaszać w na piśmie, listem poleconym na adres Usługodawcy lub
      elektronicznie na adres:
      <a href="mailto:labgears@adamed.com">labgears&#64;adamed.com</a>
      .
    </li>
    <li>Reklamacja powinna zostać złożona w terminie 14 dni od dnia stwierdzenia okoliczności uzasadniających jej złożenie.</li>
    <li>
      Reklamacja zostanie rozpatrzona przez Wydawcę w terminie 30 dni, a Użytkownik zostanie powiadomiony o jej wyniku w taki sam sposób w
      jaki została złożona.
    </li>
  </ol>

  <h3 appScrollSection="service-providers-responsibility">Odpowiedzialność Usługodawcy</h3>
  <ol>
    <li>
      Usługodawca nie ponosi odpowiedzialności za zakłócenia w prawidłowym funkcjonowaniu Serwisu, a także utratę danych Użytkownika
      powstałe w wyniku działania siły wyższej, osób trzecich oraz w wyniku działania Wydawcy zmierzającego do poprawy funkcjonalności
      Serwisu. Usługodawca zobowiązuje się w miarę możliwości informować z wyprzedzeniem Użytkowników Serwisu o zakłóceniach w jego
      funkcjonowaniu, w szczególności o przerwach konserwacyjnych.
    </li>
    <li>
      Usługodawca nie odpowiada za działania osób trzecich polegające na niezgodnym z powszechnie obowiązującym prawem lub niniejszym
      Regulaminem wykorzystywaniu materiałów umieszczonych w Serwisie.
    </li>
    <li>
      Usługodawca nie odpowiada ponadto wobec Użytkowników i osób trzecich za: 1) treść danych zamieszczanych przez Użytkowników w
      formularzach rejestracyjnych; 2) jakiekolwiek szkody Użytkownika spowodowane nieprawidłowym zapisem lub odczytem danych pobieranych
      przez Użytkowników; 3) sposób, w jaki konta będą wykorzystywane przez Użytkowników; 4) szkody powstałe w wyniku wyłączenia lub awarii
      systemu teleinformatycznego, awarii sieci energetycznej; 5) niemożność zalogowania się w Serwisie spowodowaną w szczególności jakością
      połączenia, awarią systemu teleinformatycznego lub sieci energetycznej, błędną konfiguracją oprogramowania Użytkownika.
    </li>
    <li>
      Usługodawca może umożliwić Użytkownikom dostęp za pośrednictwem Serwisu do usług świadczonych przez osoby trzecie. W takim przypadku
      Usługodawca nie ponosi żadnej odpowiedzialności za działania lub zaniechania osób trzecich.
    </li>
  </ol>

  <h3 appScrollSection="final-provisions">Postanowienia końcowe</h3>
  <ol>
    <li>
      We wszystkich sprawach dotyczących korzystania z Serwisu mają zastosowanie odpowiednie przepisy prawa obowiązujące na terytorium
      Rzeczpospolitej Polskiej.
    </li>
    <li>
      Usługodawca zastrzega prawo do dokonywania zmian i modyfikacji niniejszego Regulaminu z co najmniej 7-dniowym wyprzedzeniem.
      Użytkownicy zostaną powiadomieni o zmianie treści Regulaminu poprzez wyświetlenie stosownej informacji podczas logowania do Serwisu
      lub/i poprzez informację wysłaną wiadomością email.
    </li>
    <li>Regulamin obowiązuje od 02.11.2023.</li>
  </ol>
</article>

<nav>
  <h2>Spis treści</h2>
  <ol>
    <li appScrollAnchor="general-provisions">Postanowienia Ogólne</li>
    <li appScrollAnchor="rules-of-using-the-website">Zasady korzystania z Serwisu</li>
    <li appScrollAnchor="complaints-procedure">Postępowanie reklamacyjne</li>
    <li appScrollAnchor="service-providers-responsibility">Odpowiedzialność Usługodawcy</li>
    <li appScrollAnchor="final-provisions">Postanowienia końcowe</li>
  </ol>
  <div appScrollAnchor="general-provisions">
    Back to top
    <i class="bi bi-arrow-up"></i>
  </div>
</nav>
