import { ChangeDetectionStrategy, Component, computed, EventEmitter, Input, Output } from '@angular/core';
import { trackById } from 'src/app/shared/_helpers';
import { DevelopmentType, ExperimentDto, ISortOption, RouteParam, SkeletonType, SortOrderTypes, SectionType } from 'src/app/shared/_models';
import { ExperimentService } from 'src/app/shared/_services/experiment';
import { sortingRotationStateAnimation } from '../animations/sorting-rotation-state.animation';
import { TemplateStoreService } from '@app/templates/services/template-store-service/template-store.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectRouteCategoryHasAnyChildCategory } from '@shared/_root-store/selectors';
import { selectSelectedProject, selectShowResearchObjects } from '@shared/_root-store/projects-store/projects.selectors';
import { toSignal } from '@angular/core/rxjs-interop';
import { selectRouteParam } from '@shared/_root-store/router-store/selectors';
import { selectSelectedAll } from '@app/shared/_root-store/experiments-store/experiments.selectors';
import { getAreaName } from '@app/domain/shared';

enum ExperimentFields {
  EXP_NAME = 'name',
  STATUS = 'status',
  DATE_OF_EXECUTION = 'date_execution',
  ADDED_BY = 'user_crt',
  DATE_ADDED = 'date_created'
}

@Component({
  selector: 'app-experiment-table-list',
  templateUrl: './experiment-table-list.component.html',
  styleUrls: ['./experiment-table-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [sortingRotationStateAnimation]
})
export class ExperimentTableListComponent {
  @Input() listData: ExperimentDto[];
  @Input() listDataLoaded: boolean;
  @Input() sort: ISortOption;
  @Output() sortChange: EventEmitter<ISortOption> = new EventEmitter<ISortOption>();

  readonly imageAreaHidden$ = this.templateStoreService.imageAreaHidden$;
  readonly parametersAreaHidden$ = this.templateStoreService.parametersAreaHidden$;
  readonly resultsAreaHidden$ = this.templateStoreService.resultsAreaHidden$;
  currentCategoryHasAnyChild$: Observable<boolean> = this.store.select(selectRouteCategoryHasAnyChildCategory);
  showResearchObjects$ = this.store.select(selectShowResearchObjects);
  categoryId = toSignal(this.store.select(selectRouteParam(RouteParam.CATEGORY_ID)));
  isChecked$ = this.store.select(selectSelectedAll);
  selectedProject = toSignal(this.store.select(selectSelectedProject));
  areaName = computed(() => getAreaName(this.selectedProject(), DevelopmentType.experiment));

  readonly trackById = trackById;
  readonly SkeletonType = SkeletonType;
  readonly experimentFields = ExperimentFields;
  readonly SectionType = SectionType;

  constructor(
    private store: Store,
    private readonly experimentService: ExperimentService,
    private readonly templateStoreService: TemplateStoreService
  ) {}

  toggleAllExperimentsSelection($event): void {
    this.experimentService.toggleAllExperimentsSelection($event, this.categoryId());
  }

  setSort(prop: string): void {
    this.sortChange.emit({
      prop: prop,
      order: this.sort.prop === prop ? this.getOppositeOrder(this.sort.order) : SortOrderTypes.ASCENDING
    });
  }

  private getOppositeOrder(order: SortOrderTypes): SortOrderTypes {
    if (order === SortOrderTypes.DESCENDING) {
      return SortOrderTypes.ASCENDING;
    }
    return SortOrderTypes.DESCENDING;
  }
}
