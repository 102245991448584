import { Component, computed, input, Input } from '@angular/core';
import { LowerCasePipe, NgClass, NgIf } from '@angular/common';

import { DevelopmentType, ProjectDto } from '@app/shared/_models';

@Component({
  selector: 'app-item-details-remove-confirmation',
  standalone: true,
  imports: [LowerCasePipe, NgIf, NgClass],
  templateUrl: './item-details-remove-confirmation.component.html',
  styleUrls: ['./item-details-remove-confirmation.component.scss']
})
export class ItemDetailsRemoveConfirmationComponent {
  project = input.required<ProjectDto>();
  developmentType = input<DevelopmentType>();
  @Input() instanceNameSingular: string;
  readonly DevelopmentType = DevelopmentType;

  mainCategoryName = computed(() =>
    this.developmentType() === DevelopmentType.researchObject
      ? this.project()?.development_type_names.research_objects
      : this.project()?.development_type_names.experiments
  );
}
