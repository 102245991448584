import { HttpErrorResponse } from '@angular/common/http';

import { Field, PreprocessedErrorDto, PreprocessedErrorItemDto } from '@shared/_models';
import { isPreprocessedError } from './is-prepocessed-error';

export function findValidationError(errorResponse: HttpErrorResponse, field: Field): PreprocessedErrorItemDto {
  if (!isPreprocessedError(errorResponse?.error)) return;

  const preprocessedError: PreprocessedErrorDto = errorResponse.error as PreprocessedErrorDto;
  const validationErrors: PreprocessedErrorItemDto[] = preprocessedError.errors;
  const match = validationErrors?.find(error => error.location === field.field_template_id);

  return match;
}
