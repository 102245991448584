import { NgIf, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ChangeEvent, CKEditorComponent, CKEditorModule } from '@ckeditor/ckeditor5-angular';
import Editor from 'ckeditor5/build/ckeditor';

import { InputWithValidationComponent } from '@app/shared/_components/inputs/input-with-validation/input-with-validation.component';
import { TextCounterComponent } from '@app/shared/_components/inputs/text-long-input/text-counter/text-counter.component';
import { CounterOptions } from '@app/shared/_components/inputs/text-long-input/text-counter/utils';

import { FieldConfigEditor } from '../../models/field-config-editor-interface';

@Component({
  selector: 'app-editor',
  standalone: true,
  imports: [NgIf, NgClass, CKEditorModule, TextCounterComponent, InputWithValidationComponent],
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {
  @Input() formControl: FormControl;
  @Input() label: string;
  @Input() maxLength: number;
  @Input() counterOptions: CounterOptions;
  @Input() backendErrorMessage: string;
  @Input() externalTouched: boolean;
  @Input() markAsInvalid: boolean;
  @Output() blurred = new EventEmitter<void>();
  @ViewChild('editor') editorEl: CKEditorComponent;

  length: number;
  Editor = Editor;
  config: FieldConfigEditor;

  ngOnInit(): void {
    this.formControl.addValidators([Validators.maxLength(this.maxLength)]);
    this.config = {
      label: this.label,
      wordCount: {
        displayCharacters: true,
        displayWords: false
      },
      toolbar: {
        items: ['bold', 'italic', 'underline', 'link', 'numberedList', 'bulletedList', 'undo', 'redo'],
        shouldNotGroupWhenFull: true
      }
    };
  }

  change({ editor }: ChangeEvent) {
    this.formControl.setValue(editor.getData());

    if (!this.formControl.touched) {
      this.formControl.markAsTouched();
    }
  }

  input($event: Event) {
    $event.stopImmediatePropagation();
  }
}
