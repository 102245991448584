<div
  [routerLink]="['../', itemData().template.category.id, itemData().id]"
  [queryParams]="{ fromRoot: (currentCategoryId$ | async) === '-1' }"
  [class.items-card--selected]="isExperimentSelected(itemData().id) | async"
  data-testid="exp-card-item"
  class="items-card experiment"
  (mouseenter)="gridItemHovered = true"
  (mouseleave)="gridItemHovered = false"
>
  <div class="items-card__checkbox">
    <div class="form-check">
      <input
        data-testid="exp-card-item-checkbox"
        class="form-check-input"
        type="checkbox"
        (click)="$event.stopPropagation()"
        (change)="toggleExperimentSelection($event, itemData().id)"
        [checked]="isExperimentSelected(itemData().id) | async"
      />
    </div>
  </div>
  <div
    data-testid="exp-card-item-oid"
    class="items-card__oid items-card__oid--{{ DevelopmentType.experiment }} items-card__oid--size-{{ firstSectionSpace() }}"
  >
    {{ itemData().oid }}
  </div>
  <!-- 1) Image x3 -->
  <div *ngIf="!hideImageArea()" [class]="imageSpaceClass()" class="items-card__segment items-card__segment--image">
    <app-experiment-status class="position-absolute" [itemData]="itemData()"></app-experiment-status>

    <div class="items-card__segment__placeholder">
      <app-grid-item-image
        [developmentType]="DevelopmentType.experiment"
        [developmentItem]="itemData()"
        [categoryName]="itemData().template.category.name"
        (metaChanged)="onImageMetaChanged($event)"
      ></app-grid-item-image>
    </div>
  </div>
  <!-- 2) Results x2 -->
  <div *ngIf="!hideResultsArea()" [class]="resultsSpaceClass()" class="items-card__segment items-card__segment--main" data-testid="results">
    <app-grid-item-result [hovered]="gridItemHovered" [results]="results()" [resultTooltip]="resultTooltip$ | async"></app-grid-item-result>
  </div>
  <!-- 3) Name x1 -->
  <header class="items-card__segment" [class]="nameSpaceClass()">
    <span
      class="items-card__name"
      [ngbTooltip]="itemData().name"
      data-test="divDocName"
      appOverflowContentPluginToNgbTooltip
      defaultTooltipValue="Name"
    >
      {{ itemData().name }}
    </span>
  </header>
  <!-- 4) ROs x1 -->
  <div *ngIf="showResearchObjects()" class="items-card__segment" data-testid="related-research-objects">
    <app-items-counter
      *ngIf="itemData().linked_objects?.length; else noExperimentObjects"
      [items]="relatedResearchObjectsNames()"
      [textBeforeCounter]="relatedResearchObjectsNames()?.[0]"
      tooltipHeader="{{ (selectedProject$ | async)?.development_type_names.research_objects }} used"
      container="body"
      defaultTooltipValue="Related {{ (selectedProject$ | async)?.development_type_names.research_objects }}"
    ></app-items-counter>
    <ng-template #noExperimentObjects>-</ng-template>
  </div>
  <!-- 5) User x1 -->
  <div class="items-card__segment">
    <div class="items-card__segment__content items-card__segment__content--vertical">
      <app-user-avatar [imageBase64]="avatar$ | async" avatarSize="0.875rem"></app-user-avatar>
      <span ngbTooltip="Added by">{{ itemData().user_crt.name }} {{ itemData().user_crt.surname }}</span>
    </div>
  </div>
  <!-- 6) Footer x1 -->
  <footer class="items-card__segment items-card__segment--footer" [class.items-card__segment--footer-centered]="hideParametersArea()">
    <span ngbTooltip="Date Added" [openDelay]="300">
      {{ itemData().date_created | date: 'dd.MM.YYYY' }}
    </span>
    @if (!hideParametersArea()) {
      <span
        [ngbTooltip]="(areaName$ | async)[SectionType.PARAMETERS]"
        [openDelay]="300"
        container="body"
        data-test="primParam"
        class="items-card__parameter"
        [disableTooltip]="parameters()?.[0]?.type === DisplayFieldType.WYSIWYG"
      >
        <app-field-value-preview
          *ngIf="parameters()?.[0]?.value; else noParameterValue"
          [field]="parameters()?.[0]"
          [minimized]="true"
        ></app-field-value-preview>
        <ng-template #noParameterValue>
          <span>-</span>
        </ng-template>
      </span>
    }
  </footer>
</div>
