import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DevelopmentType } from '@app/shared/_models';
import { CloseReason } from '@shared/_components/generic-modal/utils';

import { ACTION_TYPE } from '../project-edit-add/project-edit-add.component';

@Component({
  selector: 'app-project-actions-modal',
  templateUrl: './project-actions-modal.component.html',
  styleUrls: ['./project-actions-modal.component.scss']
})
export class ProjectActionsModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter<null>();
  @Output() takeProjectAction = new EventEmitter<null>();
  @Input() actionType: ACTION_TYPE;
  @Input() projectName: string;
  readonly DevelopmentType = DevelopmentType;
  readonly ACTION_TYPE = ACTION_TYPE;
  bodyHeader: string;
  bodyPreLabel: string;
  bodyPostLabel: string;
  bodyLabelQuestion: string;
  cancelButtonLabel: string;
  actionButtonLabel: string;

  ngOnInit() {
    switch (this.actionType) {
      case this.ACTION_TYPE.LEAVE:
        this.bodyHeader = 'You are leaving project';
        this.bodyPreLabel = 'If you leave';
        this.bodyPostLabel = ', you will lose the ability to view data from this project, i.e. objects, experiments, file, samples.';
        this.bodyLabelQuestion = 'Do you want to leave the project?';
        this.cancelButtonLabel = "No, I'm staying";
        this.actionButtonLabel = "Yes, I'm leaving";
        break;
      case this.ACTION_TYPE.JOIN:
        this.bodyHeader = 'Join project';
        this.bodyPreLabel = 'You can join';
        this.bodyPostLabel = ' project by sending a request to the team leaders.';
        this.bodyLabelQuestion = 'Do you want to send a request to join the project?';
        this.cancelButtonLabel = "No, I'm fine";
        this.actionButtonLabel = 'Send request';
        break;
      case this.ACTION_TYPE.DELETE:
        this.bodyHeader = 'Delete';
        this.bodyPreLabel = 'Are you sure you want to remove this project?';
        this.bodyPostLabel = 'This operation can not be undone!';
        this.cancelButtonLabel = 'No, back to edit';
        this.actionButtonLabel = 'Yes, delete';
        break;
      default:
        return '';
    }
  }

  close(reason: CloseReason) {
    if (reason === CloseReason.CLOSE) {
      this.closeModal.emit();
    } else if (reason === CloseReason.ACCEPT) {
      this.takeProjectAction.emit();
    }
  }
}
