<div data-testid="file-card" [ngClass]="{ 'file-card-excel': uploadExcel, 'file-card-sharing': fileSharing }" class="d-flex file-card">
  <app-file-thumb *ngIf="!uploadExcel && (!fileSharing || !fileUploadInProgress)" class="me-2"></app-file-thumb>
  <app-file-thumb-rotate
    *ngIf="uploadExcel || (fileSharing && fileUploadInProgress)"
    [fileAnalyzed]="excelAnalyzed && analyzeExcel"
    [fileFailed]="fileUploadFail"
    class="me-2"
  ></app-file-thumb-rotate>
  <div [ngClass]="{ 'file-text-excel': uploadExcel }" class="d-flex flex-column overflow-hidden flex-fill">
    <div *ngIf="!uploadExcel && !fileSharing" class="file-card__progress-bar-container">
      <div
        class="file-card__progress-bar"
        [style.width]="failureMessage ? '100%' : fileUploadProgress + '%'"
        [ngClass]="{ 'file-card__progress-bar--success': fileId, 'file-card__progress-bar--fail': failureMessage }"
      ></div>
    </div>
    <div class="file-card__sharing-label" *ngIf="fileSharing && !fileUploadInProgress && !fileUpdateComplete">File</div>
    <div class="file-card__sharing-label" *ngIf="fileSharing && fileUploadInProgress">File updating...</div>
    <div class="file-card__uploading" *ngIf="uploadExcel && !analyzeExcel && !fileUploadFail">Importing...</div>
    <div class="file-card__updated" *ngIf="fileSharing && fileUpdateComplete">File updated succesfully</div>
    <div class="file-card__analyzing" *ngIf="uploadExcel && analyzeExcel && !excelAnalyzed && !fileUploadFail">Analyzing...</div>
    <div *ngIf="uploadExcel && analyzeExcel && excelAnalyzed && !fileUploadFail">Analyzed</div>
    <div class="file-card__uploading-failed" *ngIf="uploadExcel && fileUploadFail">Import failed</div>
    <div class="overflow-ellipsis file-card__file-name">{{ fileName | fileWithoutExtension }}</div>
    <div class="d-flex justify-content-between file-card__meta-data-container">
      <div class="file-card__file-size">{{ fileSize | formatFileSize }}</div>
      <div class="file-card__file-extension" data-testid="file-extension">
        {{ uploadExcel ? getFileExtention(fileName) : (fileExtension | mimeSubtype) }}
      </div>
    </div>
    <!-- <div class="file-card__share-button" *ngIf="fileSharing">CHANGE THE FILE AND UPDATE IT IN SHARED LIST</div> -->
    <input type="file" class="d-none lg-files-modal__input" id="fileInput" (change)="onFileSelected($event)" />
    <label class="file-card__share-button" *ngIf="fileSharing" for="fileInput">CHANGE THE FILE AND UPDATE IT IN SHARED LIST</label>
    <div *ngIf="!fileSharing">
      <span class="file-card__failure-message" *ngIf="failureMessage">{{ failureMessage }}</span>
      <button class="file-card__action-btn file-card__cancel-btn" (click)="onCancelBtnClick()" *ngIf="!fileId && !failureMessage">
        {{ uploadExcel && analyzeExcel && excelAnalyzed && !fileUploadFail ? 'REMOVE' : 'CANCEL' }}
      </button>
      <button
        data-testid="img-delete-button"
        class="file-card__action-btn file-card__remove-btn"
        [disabled]="disabled"
        (click)="onRemoveBtnClick()"
        *ngIf="fileId && !failureMessage"
      >
        remove
      </button>
    </div>
  </div>
</div>
