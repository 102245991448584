<div ngbDropdown class="more-actions">
  <button
    ngbDropdownToggle
    [disabled]="disabled()"
    [ngbTooltip]="tooltip()"
    (click)="$event.stopPropagation()"
    triggers="hover"
    class="more-actions__icon more-actions__icon-more-actions"
    data-testid="more-actions-menu-button"
  >
    <app-icon
      width="1rem"
      height="1rem"
      fileName="more_icon.svg"
      [color]="toggleIconColor()"
      [hoverColor]="toggleIconHoverColor()"
    ></app-icon>
  </button>
  <div ngbDropdownMenu class="dropdown__menu">
    @for (action of actions(); track action; let index = $index) {
      <a
        *ngIf="action; else separator"
        ngbDropdownItem
        class="dropdown__menu__item"
        (click)="$event.stopPropagation(); action.callback(entity())"
        (mouseenter)="onMenuItemHoverChange(true, index)"
        (mouseleave)="onMenuItemHoverChange(false, index)"
        data-testid="dropdown-more-item"
      >
        <app-icon
          *ngIf="action.icon"
          [width]="action.icon.width"
          [height]="action.icon.height"
          [fileName]="action.icon.fileName"
          [color]="index === hovered() ? action.icon.hoverColor ?? action.icon.color : action.icon.color"
        ></app-icon>
        {{ action.label }}
      </a>
      <ng-template #separator>
        <hr class="dropdown__menu__separator" />
      </ng-template>
    }
  </div>
</div>
