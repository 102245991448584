import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, EMPTY } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { toMessage } from '@app/domain/error-handling';
import { ToastService } from '@shared/_modules/toast/toast.service';
import { NavigationService } from '@shared/_services/navigation.service';
import { WorkspaceApiService } from '@shared/_services/workspace/workspace-api.service';

import * as actions from './workspaces.actions';
import { selectInitialWorkspace, selectSelectedOrInitialWorkspace } from './workspaces.selectors';
import { notifyAboutError } from '../app-store/app.actions';

@Injectable()
export class WorkspacesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly workspaceApiService: WorkspaceApiService,
    private readonly toastService: ToastService,
    private readonly store: Store,
    private readonly navigationService: NavigationService,
    private readonly router: Router
  ) {}

  setAsFavorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setAsFavorite),
      withLatestFrom(this.store.select(selectSelectedOrInitialWorkspace)),
      switchMap(([_, currentWorkspace]) =>
        this.workspaceApiService.setFavorite(currentWorkspace.id).pipe(
          map(data => actions.loadWorkspacesSuccess({ data })),
          catchError(errorResponse => of(actions.loadWorkspacesFailure({ errorResponse })))
        )
      )
    )
  );

  invite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.invite),
      concatMap(({ workspaceId, user, onSuccess, onFail }) =>
        this.workspaceApiService.invite(workspaceId, user).pipe(
          tap(workspace => {
            this.toastService.show('Invitation sent successfully', {
              header: 'Invite sent',
              type: 'success'
            });

            onSuccess?.(workspace);
          }),
          map(workspace => actions.inviteSuccess({ workspace })),
          catchError(errorResponse => {
            onFail?.(errorResponse);

            return of(notifyAboutError({ errorResponse }));
          })
        )
      )
    )
  );

  acceptPending$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.acceptPendingMember),
      concatMap(({ workspaceId, userId }) =>
        this.workspaceApiService.acceptPendingMember(workspaceId, userId).pipe(
          map(workspace => actions.acceptPendingMemberSuccess({ workspace })),
          catchError(errorResponse => of(notifyAboutError({ errorResponse })))
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.update),
      concatMap(({ workspaceId, changes, onSuccess, onFail }) =>
        this.workspaceApiService.update(workspaceId, changes).pipe(
          tap(workspace => {
            onSuccess?.(workspace);
          }),
          map(workspace => actions.updateSuccess({ workspace })),
          catchError(errorResponse => {
            onFail?.(errorResponse);

            return of(actions.updateFailure({ errorResponse }));
          })
        )
      )
    )
  );

  updateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.updateSuccess),
        tap(() => {
          this.toastService.show('Workspace updated successfully', {
            header: 'Workspace updated',
            type: 'success'
          });
        })
      ),
    { dispatch: false }
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.create),
      concatMap(({ data, onSuccess, onFail }) =>
        this.workspaceApiService.create(data).pipe(
          tap(workspace => {
            this.toastService.show('Workspace created successfully', {
              header: 'Workspace created',
              type: 'success'
            });
            onSuccess?.(workspace);

            this.navigationService.goToProjectsList(workspace.id);
          }),
          map(workspace => actions.createSuccess({ workspace })),
          catchError(errorResponse => {
            onFail?.(errorResponse);

            return of(actions.createFailure({ errorResponse }));
          })
        )
      )
    )
  );

  loadWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadWorkspaces),
      concatMap(() =>
        this.workspaceApiService.getList({ include_pending: true }).pipe(
          map(data => actions.loadWorkspacesSuccess({ data })),
          catchError(errorResponse => of(actions.loadWorkspacesFailure({ errorResponse })))
        )
      )
    )
  );

  onLoadedEmptyWorkspaces$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.loadWorkspacesSuccess),
        tap(data => {
          if (data.data.length === 0) {
            this.router.navigateByUrl('app/error');
          }
        })
      );
    },
    { dispatch: false }
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteWorkspace),
      concatMap(({ workspaceId, onSuccess }) =>
        this.workspaceApiService.delete(workspaceId).pipe(
          tap(() => {
            this.toastService.show('Deleted workspace successfully', {
              header: 'Deleted',
              type: 'success'
            });
            onSuccess?.();
          }),
          map(() => actions.deleteWorkspaceSuccess({ workspaceId })),
          catchError(errorResponse => {
            if ([400, 404, 422].includes(errorResponse.status)) {
              this.toastService.show(toMessage(errorResponse), {
                header: 'Failed to delete workspace',
                type: 'danger'
              });

              return EMPTY;
            }

            return of(notifyAboutError({ errorResponse }));
          })
        )
      )
    )
  );

  onDeleteWorkspaceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.deleteWorkspaceSuccess),
        withLatestFrom(this.store.select(selectInitialWorkspace())),
        tap(([_, workspace]) => {
          this.navigationService.goToProjectsList(workspace.id);
        })
      ),
    { dispatch: false }
  );

  deleteMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteMembers),
      concatMap(({ workspaceId, members }) =>
        this.workspaceApiService.deleteMembers(workspaceId, members).pipe(
          tap(() => {
            this.toastService.show('Deleted members successfully', {
              header: 'Deleted',
              type: 'success'
            });
          }),
          map(workspace => actions.deleteMembersSuccess({ workspace })),
          catchError(errorResponse => {
            return of(notifyAboutError({ errorResponse }));
          })
        )
      )
    )
  );

  leave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.leave),
      concatMap(({ workspaceId, removeRequest, onSuccess }) =>
        this.workspaceApiService.leave(workspaceId).pipe(
          tap(() => {
            this.toastService.show(removeRequest ? 'You removed join request successfully' : 'You left workspace successfully', {
              header: removeRequest ? 'You removed join request' : 'You left workspace',
              type: 'success'
            });
            onSuccess?.();
          }),
          map(() => actions.leaveSuccess({ workspaceId })),
          catchError(errorResponse => {
            if ([400, 404, 422].includes(errorResponse.status)) {
              this.toastService.show(toMessage(errorResponse), {
                header: removeRequest ? 'Failed to remove join request' : 'Failed to leave workspace',
                type: 'danger'
              });

              return EMPTY;
            }

            return of(notifyAboutError({ errorResponse }));
          })
        )
      )
    )
  );

  onLeaveSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.leaveSuccess),
        withLatestFrom(this.store.select(selectInitialWorkspace())),
        tap(([_, workspace]) => {
          this.navigationService.goToProjectsList(workspace.id);
        })
      ),
    { dispatch: false }
  );

  sendJoinRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.sendJoinRequest),
      concatMap(({ code, onSuccess }) =>
        this.workspaceApiService.sendJoinRequest(code).pipe(
          tap(() => {
            this.toastService.show('Request to join the workspace sent successfully', {
              header: 'Joined the workspace',
              type: 'success'
            });
            onSuccess?.();
          }),
          map(workspace => actions.sendJoinRequestSuccess({ workspace })),
          catchError(errorResponse => {
            if ([400, 404, 409].includes(errorResponse.status)) {
              this.toastService.show(toMessage(errorResponse), {
                header: 'The request was not sent',
                type: 'danger'
              });

              return EMPTY;
            }

            return of(notifyAboutError({ errorResponse }));
          })
        )
      )
    )
  );

  onLoadWorkspacesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.loadWorkspacesFailure),
        tap(({ errorResponse }) => {
          // TODO: to prawdopodobnie do usunięcia (w tym momencie user jest już zalogowany więc nigdy nie dostanie 403)
          if (errorResponse.status === 403) {
            this.toastService.show('Please contact your administrator.', {
              header: 'You do not have access to requested workspace',
              type: 'danger'
            });

            return this.router.navigateByUrl('signin-form');
          }
        })
      ),
    { dispatch: false }
  );

  changeRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.changeRole),
      concatMap(({ workspaceId, user_id, role }) =>
        this.workspaceApiService.changeRole(workspaceId, user_id, role).pipe(
          tap(() => {
            this.toastService.show('Role changed successfully', {
              header: 'Role changed',
              type: 'success'
            });
          }),
          map(workspace => actions.changeRoleSuccess({ workspace })),
          catchError(errorResponse => of(notifyAboutError({ errorResponse })))
        )
      )
    )
  );

  informRoleChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.informRoleChange),
      concatMap(({ workspaceId, users_id }) =>
        this.workspaceApiService.informRoleChange(workspaceId, users_id).pipe(
          tap(() => {
            this.toastService.show('Mail sent to users informing about role change', {
              header: 'Mail sent to users ',
              type: 'success'
            });
          }),
          map(workspace => actions.informRoleChangeSuccess({ workspace })),
          catchError(errorResponse => of(notifyAboutError({ errorResponse })))
        )
      )
    )
  );
}
