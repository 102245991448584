<div class="form-group">
  <div class="input-wrapper" [class.input-wrapper--with-icon]="iconPath">
    <i *ngIf="iconPath" class="{{ iconPath }}"></i>
    <input
      [value]="ngModel()"
      (input)="onValueChange($event.target.value)"
      class="form-control input"
      [type]="type"
      [class.input--invalid]="hasError"
      [placeholder]="placeholder ?? ''"
    />
    <button type="button" (click)="onClear()" *ngIf="ngModel()" [ngClass]="{ 'btn-clear-invite': iconPath }"
            class="btn btn-clear">
    </button>
  </div>
</div>
