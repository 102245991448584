import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iif, switchMap } from 'rxjs';

import { AuthService } from '@app/_auth/auth.service';
import { AuthSignInMicrosoftResponse } from '@app/_auth/interface';
import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { WorkspaceDto } from '@app/shared/_models/workspace';

@Component({
  selector: 'app-signin-adamed',
  templateUrl: './signin-adamed.component.html',
  styleUrls: ['./signin-adamed.component.scss']
})
export class SigninAdamedComponent implements OnInit {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private workspaceService: WorkspaceService
  ) {}

  ngOnInit(): void {
    const code = this.activatedRoute.snapshot.queryParamMap.get('code');
    const queryParamStateKey = this.activatedRoute.snapshot.queryParamMap.get('state');
    const returnUrl = sessionStorage.getItem('returnUrl');
    const workspaceIdFromReturnUrl = returnUrl?.split('/')[2];

    if (code && queryParamStateKey) {
      // ten endpoint zwraca token po pomyślnej autentykacji przez zewnętrzny serwis (na podstawie 'code' które pochodzi
      // z zewnętrznego serwisu)
      this.authService
        .callBackMicrosoft(code, queryParamStateKey)
        .pipe(
          switchMap(() =>
            iif(
              () => !!workspaceIdFromReturnUrl,
              this.workspaceService.getWorkspaceById(workspaceIdFromReturnUrl),
              this.workspaceService.getInitialWorkspace()
            )
          )
        )
        .subscribe((workspace: WorkspaceDto) => {
          this.authService.redirectToInitialPage(workspace?.id, returnUrl);
          sessionStorage.removeItem('returnUrl');
        });
    }
  }

  signInWithMicrosoft() {
    // ten endpoint pobiera url do zewnętrznego serwisu logowania (microsoft)
    this.authService.signInWithMicrosoft().subscribe((response: AuthSignInMicrosoftResponse) => {
      const returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');

      if (returnUrl) {
        sessionStorage.setItem('returnUrl', returnUrl);
      }

      // przekierowanie
      window.location.href = response.authorization_url;

      // po pomyślnym zalogowaniu zewnętrzny serwis przekieruje klienta z powrotem na nasz adres logowania umieszczająć
      // ten sam query param 'state' i query param 'code'
    });
  }
}
