<div
  class="table-body-row align-items-center table-body-row--hover-labels"
  [ngClass]="{
    'table-body-row--selected': (isResearchObjectSelected(itemData()?.id) | async) || showInstancesDetails === true
  }"
>
  <div class="datatable__row-wrapper align-items-center">
    <div class="col checkbox-col selection-col text-center">
      <div class="form-check">
        <input
          data-testid="ro-list-view-item-checkbox"
          class="form-check-input"
          type="checkbox"
          (change)="toggleResearchObjectSelection($event, itemData()?.id)"
          [checked]="isResearchObjectSelected(itemData()?.id) | async"
        />
      </div>
    </div>
    <div *ngIf="(currentCategoryHasAnyChild$ | async) || !showImages()" class="col image-col table-body-row__image-col text-left">
      <app-table-item-image
        [imageAreaHidden]="showImages()"
        [developmentType]="DevelopmentType.researchObject"
        [developmentItem]="itemData()"
        [categoryName]="itemData().template.category.name"
      ></app-table-item-image>
    </div>
    <div class="col col-narrow oid-col text-left" data-title="ID" appOverflowContentPluginToNgbTooltip ngbTooltip="{{ itemData().oid }}">
      {{ itemData()?.oid }}
    </div>
    <div class="col name-col text-left" data-title="Name">
      <div class="name-col-content" appOverflowContentPluginToNgbTooltip ngbTooltip="{{ itemData().name }}">
        {{ itemData().name }}
      </div>
    </div>
    <ng-container *ngIf="currentCategoryHasAnyChild$ | async; else additionalParamsAndBatchSection">
      <div
        class="col category-col text-left"
        appOverflowContentPluginToNgbTooltip
        ngbTooltip="{{ itemData().template.category.name }}"
        data-title="Category"
      >
        {{ itemData().template.category.name }}
      </div>
      <div class="col added-by-col text-left" data-title="Added By">
        <div class="user-container">
          <app-user-avatar [imageBase64]="avatar$ | async" avatarSize="0.875rem"></app-user-avatar>
          <div data-test="userAdding" appOverflowContentPluginToNgbTooltip ngbTooltip="{{ itemData().user_crt.coalesced_email || '-' }}">
            {{ itemData().user_crt.coalesced_email || '-' }}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #additionalParamsAndBatchSection>
      <ng-container *ngFor="let param of parameters() | slice: 0 : 4; trackBy: trackByIndex">
        <div
          class="col parameter-col text-left"
          *ngIf="!parametersAreaHidden()"
          [ngbTooltip]="(param | itemReadValue) || '-'"
          [disableTooltip]="param.type !== DisplayFieldType.TEXT_LONG"
          [attr.data-title]="param.name"
        >
          <app-field-value-preview [field]="param" [viewMode]="ViewMode.table" [minimized]="true"></app-field-value-preview>
        </div>
      </ng-container>
    </ng-template>
    <div
      *ngIf="!instanceAreaHidden() || (currentCategoryHasAnyChild$ | async)"
      class="col col-narrow batch-col text-left"
      [attr.data-title]="showCounter() ? instanceSectionName() : null"
    >
      <app-items-counter
        [items]="instancesNumbers()"
        [tooltipHeader]="samplesAreaName()"
        [counterVisibleForLengthOne]="true"
        [showCounter]="showCounter()"
      ></app-items-counter>
    </div>
    <div
      *ngIf="showExperiments()"
      class="col col-narrow used-in-exp-col text-left"
      [attr.data-title]="'Used In ' + selectedProject()?.development_type_names.experiments"
    >
      {{ itemData().experiments.length || '0' }}
    </div>
    <div
      class="col col-narrow date-col text-left"
      appOverflowContentPluginToNgbTooltip
      ngbTooltip="{{ itemData()?.date_created | date: 'dd.MM.YYYY' }}"
      data-title="Date Added"
    >
      {{ itemData()?.date_created | date: 'dd.MM.YYYY' }}
    </div>
    <div class="col actions-col lg-table__action-btn-container position-relative show-contents-on-hover">
      <ng-container
        *ngTemplateOutlet="actionsBtnAndPopover; context: { itemData: itemData(), $implicit: contextMenuConfig }"
      ></ng-container>
    </div>
    <button
      class="table-body-row__item-btn table-body-row__item-btn--ro"
      [ngClass]="{ 'table-body-row__item-btn--always-visible': showInstancesDetails }"
      (click)="navigationService.goToResearchObjectInfo(workspaceId, projectId(), itemData().id, itemData().template.category_id)"
    >
      <img src="./../../../../../assets/icons/chevron_3_icon.svg" class="table-body-row__item-btn-icon" alt="arrow right icon" />
    </button>
  </div>
  <app-research-object-table-item-details
    *ngIf="
      (!(currentCategoryHasAnyChild$ | async) && !instanceAreaHidden() && itemData().instances?.length > 0) ||
      ((currentCategoryHasAnyChild$ | async) && !showInstances() && itemData().instances?.length > 0)
    "
    [researchObject]="itemData()"
    [detailsMessage]="instanceDetailsMessage()"
    (showInstancesDetailsChanged)="switchShowInstancesDetails($event)"
  ></app-research-object-table-item-details>
</div>

<ng-template let-index="index" let-itemData="itemData" let-contextMenuConfig #actionsBtnAndPopover>
  <button
    #popover="ngbPopover"
    class="btn btn-transparent px-0 lg-table__action-btn"
    [ngClass]="{ active: popover.isOpen() }"
    [ngbPopover]="actionsPopoverContent"
    [popoverTitle]="actionsPopoverTitle"
    popoverClass="lg-popover"
    container="body"
    appClickStopPropagation
    triggers="click"
  >
    <img src="../../../assets/icons/ellipsis_icon.svg" alt="ellipsis icon" />
  </button>

  <ng-template #actionsPopoverTitle>{{ contextMenuConfig?.title || 'Actions' | uppercase }}</ng-template>
  <ng-template #actionsPopoverContent>
    <ng-container *ngFor="let action of contextMenuConfig.actions; last as isLast; trackBy: trackByIndex">
      <a href="" (click)="action.fn($event, itemData)">{{ action.name }}</a>
      <hr *ngIf="!isLast" divider />
    </ng-container>
  </ng-template>
</ng-template>
