import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, tap, Observable } from 'rxjs';

import { UserDto } from '@app/shared/_models';
import { MemberEdit, MemberRole } from '@app/shared/_models/member';
import { IUserWithRole } from '@app/shared/_models/project';
import { ProjectsApiService } from '@app/shared/_services/project';
import { SearchSelectComponent } from '@shared/_components/search-select/search-select.component';

enum FORM_KEYS {
  MEMBERS_DROPDOWN_CONTROL = 'membersDropdownControl'
}

@Component({
  selector: 'app-members-dropdown',
  standalone: true,
  imports: [CommonModule, SearchSelectComponent],
  templateUrl: './members-dropdown.component.html',
  styleUrls: ['./members-dropdown.component.scss']
})
export class MembersDropdownComponent implements OnInit {
  @Input() members: MemberEdit[];
  membersDropdown: FormGroup;
  membersSearchValues: IUserWithRole[] = [];
  readonly FORM_KEYS = FORM_KEYS;
  memberAdd = output<string>();

  constructor(private readonly projectsApiService: ProjectsApiService) {
    this.#getMembersByWorkspace();
  }

  searchMembers = (searchValue$: Observable<string>) =>
    searchValue$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(searchValue => this.#filterMembersBySearch(searchValue))
    );

  ngOnInit() {
    this.membersDropdown = new FormGroup({
      membersDropdownControl: new FormControl(null)
    });
  }

  checkForMembersDuplicates() {
    this.membersSearchValues = this.membersSearchValues.filter(
      searchMember => !this.members.find(member => member.user.id === searchMember.user.id)
    );
  }

  onAddMember(member: IUserWithRole) {
    if (!member) {
      return;
    }
    this.memberAdd.emit(member.user.id);
    this.membersDropdown.controls[FORM_KEYS.MEMBERS_DROPDOWN_CONTROL].setValue(null);
  }

  #filterMembersBySearch(searchValue: string) {
    if (searchValue == '') {
      return [];
    }
    return this.membersSearchValues.filter(member =>
      `${member.user.name} ${member.user.surname}`.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  #getMembersByWorkspace() {
    this.projectsApiService
      .getUsers()
      .pipe(
        takeUntilDestroyed(),
        tap(users => this.#setMembersSearchValues(users))
      )
      .subscribe();
  }

  #setMembersSearchValues(users: UserDto[]) {
    this.membersSearchValues = users.map(
      user =>
        ({
          user: user,
          role: MemberRole.reader
        }) as IUserWithRole
    );
  }
}
