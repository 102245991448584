import { UserDto } from './user.interface';

export interface MemberEdit {
  user: UserDto;
  roleBeforeEdit: string;
  roleAfterEdit: string;
  isRemoved: boolean;
}

export enum MemberRole {
  owner = 'Owner',
  lead = 'Lead',
  editor = 'Editor',
  reader = 'Reader',
  member = 'Member'
}

export enum MemberPermission {
  READ = 'read',
  WRITE = 'write'
}

export interface IMembersFormData {
  owners: UserDto[];
  leads: UserDto[];
  editors: UserDto[];
  readers: UserDto[];
}

export interface MemberDto {
  user_id: string;
  permission: MemberPermission;
}
