import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, EMPTY, map, mergeMap, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { ExperimentsApiService } from '@shared/_services/experiment';
import { ImageApiService } from '@shared/_services/image/image-api.service';
import * as actions from './experiments.actions';
import { FileType, RouteParam } from '@shared/_models';
import { FileDownloadService } from '@shared/_services/file';
import { ExperimentsStoreService } from './experiments-store.service';
import { ToastService } from '@shared/_modules/toast/toast.service';
import { ItemsRemoveService } from '@shared/_services/item/items-remove.service';
import { RouterService } from '@shared/_services/router/router.service';
import { getPagesCount } from '@shared/_helpers';
import { selectCategoriesDictionary } from '@shared/_root-store/categories-store/categories.selectors';
import { selectTemplatesDictionary } from '@shared/_root-store/category-templates-store/category-templates.selectors';
import { toExperiment, toExperiments } from '@shared/dto-adapters/experiment';
import { namePlural } from '@shared/_helpers/name-plural';
import { loadImagesContent } from '@shared/_root-store/images-store/images.actions';
import { ImageSize, toMainImages } from '@app/domain/image';
import { loadAvatars } from '@shared/_root-store/users-store/users.actions';
import { toAvatarIds } from '@app/domain/user';
import { toMessage } from '@app/domain/error-handling';
import { getAttachParams } from '@app/main/utils';
import { notifyAboutError } from '@shared/_root-store/app-store/app.actions';

@Injectable()
export class ExperimentsEffects {
  #attachProjectId = getAttachParams(RouteParam.PROJECT_ID);

  constructor(
    private readonly actions$: Actions,
    private readonly experimentsApiService: ExperimentsApiService,
    private readonly imageApiService: ImageApiService,
    private readonly toastService: ToastService,
    private readonly fileDownloadService: FileDownloadService,
    private readonly datePipe: DatePipe,
    private readonly experimentsStoreService: ExperimentsStoreService,
    private readonly itemsRemoveService: ItemsRemoveService,
    private readonly routerService: RouterService,
    private readonly store: Store,
    private readonly router: Router
  ) {}

  // LOAD

  loadExperiments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadExperiments),
      concatMap(({ params }) =>
        this.experimentsApiService.getList(params).pipe(
          withLatestFrom(this.store.select(selectCategoriesDictionary), this.store.select(selectTemplatesDictionary)),
          map(toExperiments),
          map(data => actions.loadExperimentsSuccess({ data })),
          catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 500) {
              this.router.navigateByUrl('app/error');
            }

            return of(actions.loadExperimentsFailure({ error }));
          })
        )
      )
    )
  );

  loadExperimentsAndStayOnCurrentPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadExperimentsAndStayOnCurrentPage),
      mergeMap(({ params }) =>
        this.experimentsApiService.getList({ ...params, page: 1 }).pipe(
          map(({ pagination }) => {
            const pageCount = getPagesCount(pagination);
            const currentPage = pageCount < params.page ? pageCount || 1 : (params.page as number);

            return currentPage;
          }),
          switchMap(page => {
            return this.experimentsApiService.getList({ ...params, page }).pipe(
              withLatestFrom(this.store.select(selectCategoriesDictionary), this.store.select(selectTemplatesDictionary)),
              map(toExperiments),
              map(experimentsForCurrentPage => {
                this.routerService.navigateToCurrentRouteButWithDifferentQueryParams({
                  page
                });

                return actions.loadExperimentsSuccess({
                  data: experimentsForCurrentPage
                });
              })
            );
          })
        )
      )
    )
  );

  reloadExperiment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.reloadExperiment),
      switchMap(({ experimentId }) =>
        this.experimentsApiService.get(experimentId).pipe(catchError(error => of(actions.reloadExperimentFailure({ error }))))
      ),
      withLatestFrom(this.store.select(selectCategoriesDictionary), this.store.select(selectTemplatesDictionary)),
      map(toExperiment),
      map(data => actions.reloadExperimentSuccess({ data }))
    )
  );

  // SELECT

  getSelectedExperimentsIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getAllExperimentsIds),
      concatMap(({ params }) =>
        this.experimentsApiService.getListOfIds(this.#attachProjectId(params)).pipe(
          map(data => actions.getAllExperimentsIdsSuccess({ data })),
          catchError(error => of(actions.getAllExperimentsIdsFailure({ error })))
        )
      )
    )
  );

  // DELETE

  deleteExperiment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteExperiment),
      mergeMap(({ experimentId, params }) => {
        return this.experimentsApiService.delete(experimentId).pipe(
          tap(experimentId => this.experimentsStoreService.unselectExperiment(experimentId)),
          map(experimentId => {
            return actions.deleteExperimentsSuccess({ deletedExperimentsIds: [experimentId], params });
          }),
          catchError(errorResponse => of(actions.deleteExperimentFailure({ errorResponse })))
        );
      })
    )
  );

  deleteExperiments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteExperiments),
      mergeMap(({ experimentsIds, params }) => {
        return this.experimentsApiService.deleteMultiple(experimentsIds).pipe(
          map(() => {
            this.experimentsStoreService.unselectExperiments(experimentsIds);
            this.itemsRemoveService.closeConfirmationModal$.next();
            return actions.deleteExperimentsSuccess({ deletedExperimentsIds: experimentsIds, params });
          }),
          catchError(() => {
            this.itemsRemoveService.closeConfirmationModal$.next();
            return of(actions.deleteExperimentsFailure());
          })
        );
      })
    )
  );

  deleteExperimentsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.deleteExperimentsSuccess),
        tap(({ deletedExperimentsIds, params }) => {
          const deleteCount = deletedExperimentsIds.length;

          this.toastService.show(`Selected ${namePlural('experiment', deleteCount)} removed successfully`, {
            header: `${namePlural('Experiment', deleteCount)} removed`,
            type: 'success'
          });

          if (deleteCount) {
            this.experimentsStoreService.loadExperimentsAndStayOnCurrentPage(params);
          }
        })
      ),
    { dispatch: false }
  );

  deleteExperimentsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.deleteExperimentsFailure),
        tap(() => {
          this.toastService.show('An error occured while removing experiments', {
            header: 'Error',
            type: 'danger'
          });
        })
      ),
    { dispatch: false }
  );

  deleteExperimentFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteExperimentFailure),
      map(({ errorResponse }) => notifyAboutError({ errorResponse }))
    )
  );

  // EXPORT

  generateCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.generateCSV),
      concatMap(({ fileName, experimentsIds }) =>
        this.experimentsApiService.generateCSV(experimentsIds).pipe(
          map((file: Blob) => actions.generateCSVSuccess({ fileName, file })),
          catchError(error => of(actions.generateCSVFailure({ error })))
        )
      )
    )
  );

  generateCSVSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.generateCSVSuccess),
        tap(({ fileName, file }) => {
          // const dateGenerated = new Date();
          // const transformedDate = this.datePipe.transform(dateGenerated, 'dd-MM-YYYY');
          const fileData = {
            name: fileName,
            type: FileType.XLSX
          };
          // const msg = this.#getGenerateCSVSuccessMessage(fileName);

          // this.toastService.show(msg.content, {
          //   classname: 'bg-success text-light',
          //   delay: 7000,
          //   header: msg.header,
          // });

          this.fileDownloadService.downloadParametrized(fileData, file);
        })
      ),
    { dispatch: false }
  );

  generateCSVFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.generateCSVFailure),
      map(({ error }) => toMessage(error)),
      map(content =>
        notifyAboutError({
          notification: {
            content,
            header: 'Export failed'
          }
        })
      )
    )
  );

  generateMultipleCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.generateMultipleCSV),
      concatMap(({ fileName, expIdsGroupedByTemplateId }) =>
        this.experimentsApiService.generateMultipleCSV(expIdsGroupedByTemplateId).pipe(
          map((file: Blob) => actions.generateMultipleCSVSuccess({ fileName, file })),
          catchError(error => of(actions.generateMultipleCSVFailure({ error })))
        )
      )
    )
  );

  generateMultipleCSVSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.generateMultipleCSVSuccess),
        tap(({ fileName, file }) => {
          const fileData = {
            name: fileName,
            type: FileType.ZIP
          };

          this.fileDownloadService.downloadParametrized(fileData, file);
        })
      ),
    { dispatch: false }
  );

  generateMultipleCSVFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.generateMultipleCSVFailure),
      map(({ error }) => toMessage(error)),
      map(content =>
        notifyAboutError({
          notification: {
            content,
            header: 'Export failed'
          }
        })
      )
    )
  );

  loadMainImagesForExperiments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadExperimentsSuccess),
      map(({ data }) => loadImagesContent({ imagesMeta: toMainImages(data), size: ImageSize.SMALL }))
    )
  );

  loadAvatarsForExperiments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadExperimentsSuccess),
      map(({ data }) => loadAvatars({ ids: toAvatarIds(data) }))
    )
  );

  updateImageMeta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateImageMeta),
      switchMap(({ data }) =>
        this.imageApiService.updateMeta(data.updateData).pipe(
          map(imageMeta => actions.updateImageMetaSucceeded({ experimentId: data.entityId, imageMeta })),
          catchError(error => of(actions.updateImageMetaFailed({ error })))
        )
      )
    )
  );

  #getGenerateCSVSuccessMessage(fileName: string): { [key: string]: string } {
    return {
      header: 'Success',
      content: `Experiment file "${fileName}" was successfully generated.`
    };
  }
}
