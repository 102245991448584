import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

//
import { currentUserLoaded, currentUserLoading, selectCurrentUser } from './users.selectors';

@Injectable({
  providedIn: 'root'
})
export class UsersStoreService {
  readonly currentUser$ = this.store.select(selectCurrentUser);
  readonly currentUserLoading$ = this.store.select(currentUserLoading);
  readonly currentUserLoaded$ = this.store.select(currentUserLoaded);

  constructor(private readonly store: Store) {}
}
