import { Pipe, PipeTransform } from '@angular/core';
import { PreprocessedErrorItemDto } from '@shared/_models';

@Pipe({
  name: 'validationError',
  standalone: true
})
export class ValidationErrorPipe implements PipeTransform {
  transform(value: PreprocessedErrorItemDto[], fieldTemplateId: string): string {
    return value.find(error => error.location === fieldTemplateId)?.message;
  }
}
