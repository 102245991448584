import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';

import { WorkspaceDto, WorkspacePendingMemberDto, CreateWorkspaceDto, UpdateWorkspaceDto, WorkspaceMemberRole } from '@shared/_models';

import { WORKSPACES_API_URL } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceApiService {
  constructor(private readonly http: HttpClient) {}

  getFavouriteWorkspace(): Observable<WorkspaceDto> {
    return this.http.get<WorkspaceDto>(`${WORKSPACES_API_URL}/favourite`);
  }

  getWorkspace(workspaceId: string): Observable<WorkspaceDto> {
    return this.http.get<WorkspaceDto>(`${WORKSPACES_API_URL}/${workspaceId}`);
  }

  getList(params: Params = {}) {
    return this.http.get<WorkspaceDto[]>(WORKSPACES_API_URL, { params });
  }

  create(workspace: CreateWorkspaceDto): Observable<WorkspaceDto> {
    return this.http.post<WorkspaceDto>(`${WORKSPACES_API_URL}`, workspace);
  }

  update(workspaceId: string, changes: Partial<UpdateWorkspaceDto>): Observable<WorkspaceDto> {
    return this.http.patch<WorkspaceDto>(`${WORKSPACES_API_URL}/${workspaceId}`, changes);
  }

  sendJoinRequest(code: string): Observable<WorkspaceDto> {
    return this.http.patch<WorkspaceDto>(`${WORKSPACES_API_URL}/${code}/request_join`, null);
  }

  setFavorite(workspaceId: string): Observable<WorkspaceDto[]> {
    return this.http.post<WorkspaceDto[]>(`${WORKSPACES_API_URL}/${workspaceId}/set_favourite`, {});
  }

  leave(workspaceId: string): Observable<WorkspaceDto> {
    return this.http.delete<WorkspaceDto>(`${WORKSPACES_API_URL}/${workspaceId}/leave_workspace`);
  }

  delete(workspaceId: string): Observable<void> {
    return this.http.delete<void>(`${WORKSPACES_API_URL}/${workspaceId}`);
  }

  deleteMembers(workspaceId: string, usersToDelete: string[]): Observable<WorkspaceDto> {
    return this.http.post<WorkspaceDto>(`${WORKSPACES_API_URL}/${workspaceId}/remove_members`, usersToDelete);
  }

  getPendingMembers() {
    return this.http.get<WorkspacePendingMemberDto[]>(`${WORKSPACES_API_URL}/pending_members`);
  }

  getInvitedWorkspaces() {
    return this.http.get<WorkspaceDto[]>(`${WORKSPACES_API_URL}/invited_workspaces`);
  }

  acceptInvite(workspaceId: string): Observable<WorkspaceDto> {
    return this.http.patch<WorkspaceDto>(`${WORKSPACES_API_URL}/${workspaceId}/accept_invite`, null);
  }

  rejectInvite(workspaceId: string): Observable<WorkspaceDto> {
    return this.http.delete<WorkspaceDto>(`${WORKSPACES_API_URL}/${workspaceId}/reject_invite`);
  }

  acceptPendingMember(workspaceId: string, userId: string): Observable<WorkspaceDto> {
    return this.http.patch<WorkspaceDto>(`${WORKSPACES_API_URL}/${workspaceId}/accept_pending?user_id=${userId}`, null);
  }

  invite(workspaceId: string, user: { email: string }) {
    return this.http.post<WorkspaceDto>(`${WORKSPACES_API_URL}/${workspaceId}/invite`, user);
  }

  changeRole(workspaceId: string, user_id: string, role: WorkspaceMemberRole) {
    return this.http.patch<WorkspaceDto>(`${WORKSPACES_API_URL}/${workspaceId}/change_role`, { user_id, role });
  }

  informRoleChange(workspaceId: string, users_id: string[]) {
    return this.http.post<WorkspaceDto>(`${WORKSPACES_API_URL}/${workspaceId}/inform_role_change`, users_id);
  }
}
