<div ngbDropdown (openChange)="isDropdownOpened = $event" class="dropdown">
  <div class="d-flex align-items-center" ngbDropdownToggle>
    <span class="dropdown__selected-item d-flex">
      <img class="dropdown__selected-item__icon" src="assets/icons/chevron_7_blue_icon.svg" />
      {{ selectedLabel() }}
    </span>

    <div ngbDropdownMenu class="dropdown__menu">
      <a
        ngbDropdownItem
        [class.dropdown__menu__item--active]="selectedValue() === item.value"
        [class.dropdown__menu__item--disabled]="item.disabled"
        class="dropdown__menu__item"
        *ngFor="let item of items()"
        (click)="onSelect(item)">
        {{ item.label }}
      </a>
    </div>
  </div>
</div>
