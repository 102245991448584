<div
  class="card"
  data-testid="project-list-grid-item"
  [ngClass]="{ 'card--inactive': !projectProxy().belongsToProject }"
  (click)="onCardClick()"
>
  <span class="card__color-bar" [ngClass]="'option-color-' + project().color"></span>
  <div class="card__top-section">
    <app-grid-item-status [status]="project().status"></app-grid-item-status>

    <div ngbDropdown class="dropdown" *ngIf="projectProxy().belongsToProject">
      <button data-testid="project-actions-dropdown-button" ngbDropdownToggle class="dropdown__toggle" (click)="$event.stopPropagation()">
        <div class="project-actions-dropdown-button__icon-border">
          <app-icon
            width="1.2rem"
            height="1.2rem"
            fileName="more_icon.svg"
            [color]="IconColors.darkGrey"
            [hoverColor]="IconColors.blue"
          ></app-icon>
        </div>
      </button>
      <div ngbDropdownMenu class="dropdown__menu" (click)="$event.stopPropagation()">
        @if (projectProxy().isLead || projectProxy().isOwner) {
          <button class="card__edit-btn" (click)="edit.emit(); $event.stopPropagation()">edit</button>
          <button class="card__members-btn" (click)="manageMembers.emit(); $event.stopPropagation()">manage members</button>
        }
        @if ((projectProxy().isLead || projectProxy().isOwner) && !projectProxy().isOwner) {
          <hr />
        }
        @if (!projectProxy().isOwner) {
          <button class="card__leave-btn" (click)="leave.emit(); $event.stopPropagation()">leave project</button>
        }
      </div>
    </div>
  </div>
  <div class="card__header">{{ project().name }}</div>
  <div class="card__description" *ngIf="project().description">
    {{ project().description }}
  </div>
  <div class="card__items">
    <span *ngIf="!hiddenResearchObjects()" class="card__objects" data-testid="count_ro">
      <img src="../../../assets/icons/atom_gradient_icon.svg" alt="research objects icon" />
      <span class="card__object_count">{{ project().count_ro }}</span>
      <span>{{ project().development_type_names.research_objects }}</span>
    </span>
    <span *ngIf="!hiddenExperiments()" class="card__objects" data-testid="count_exp">
      <img src="../../../assets/icons/chart_gradient_icon.svg" alt="experiments icon" />
      <span class="card__object_count">{{ project().count_exp }}</span>
      <span>{{ project().development_type_names.experiments }}</span>
    </span>
  </div>
  <div class="card__bottom-section">
    <span class="card__project-duration" ngbTooltip="Date added: {{ project().date_created | date: 'dd.MM.YYYY' }}">
      <img src="../../../assets/icons/calendar_icon.svg" alt="calendar icon" class="card__calendar-icon" />
      {{ daysSinceProjectStart() }}
      Days
    </span>
    <span class="card__members">
      <span class="d-inline-flex">
        <app-user-avatar
          *ngFor="let member of projectOwnerAndLeads() | slice: [0, 3]"
          [imageBase64]="(member.avatar$ | async)?.base64"
          avatarSize="1.3rem"
          ngbTooltip="{{ member?.name }} {{ member?.surname }}"
          data-testid="project-owner-avatar"
          class="card__avatar"
        ></app-user-avatar>
      </span>
      <span *ngIf="projectOwnerAndLeads().length > 3" class="card__member-number" [ngbTooltip]="membersFullNameList">
        {{ projectOwnerAndLeads().length }}
      </span>
    </span>
  </div>
</div>

<ng-template #membersFullNameList>
  <div *ngFor="let member of projectOwnerAndLeads()">{{ member?.name }} {{ member?.surname }}</div>
</ng-template>
