import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { ListItemResultBase } from '@app/shared/_components/item/list-item-result-base/list-item-result-base.component';
import { IconColors } from '@shared/_components/icon/utils/icon-colors';

@Component({
  selector: 'app-grid-item-result',
  templateUrl: './grid-item-result.component.html',
  styleUrls: ['./grid-item-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridItemResultComponent extends ListItemResultBase {
  hovered = input(false);
  resultTooltip = input.required<string>();
  IconColors = IconColors;

  constructor(readonly decimalPipe: DecimalPipe) {
    super(decimalPipe);
  }
}
