import { toFields } from '@app/shared/dto-adapters/field';
import { DisplayFieldType, CategoryTemplateDto, Field, InstanceDto, ResearchObject, SectionType } from '@shared/_models';

export const SERIAL_NUMBER = 'serial_number';

export function getInstanceViewData(instance: InstanceDto, researchObject: ResearchObject): Field[] {
  const fields = [
    {
      name: 'ID',
      label: 'ID',
      type: DisplayFieldType.TEXT,
      value: instance?.serial_number,
      location: SERIAL_NUMBER,
      options: {
        required: true
      }
    }
  ] as Field[];

  if (instance) {
    const instanceSection = researchObject.template.sections.find(section => section.id === instance.section_id);
    return fields.concat(toFields(instanceSection.fields, instance.field_values));
  }

  return fields.concat(getInstanceTemplateFields(researchObject.template));
}

function getInstanceTemplateFields(template: CategoryTemplateDto): Field[] {
  const instanceSection = template?.sections?.find(section => section.type === SectionType.INSTANCES);

  return instanceSection?.fields?.map<Field>(
    field =>
      ({
        ...field,
        label: field.label || field.name,
        type: field.display_options.display_type,
        value: null,
        field_template_id: field.id
      }) as Field
  );
}
