import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { catchError, EMPTY, iif, switchMap, tap } from 'rxjs';

import { AuthRoutingPath } from '@app/_auth/auth-routing-path';
import { AuthService } from '@app/_auth/auth.service';
import { PasswordRoutingPath } from '@app/_auth/password/password-routing-path';
import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { WorkspaceDto } from '@app/shared/_models/workspace';
import { ToastService } from '@app/shared/_modules/toast/toast.service';
import { EmailWithDomainValidator } from '@app/shared/_validators/email-with-domain.validator';

enum FORM_KEYS {
  USERNAME = 'username',
  PASSWORD = 'password'
}
@Component({
  selector: 'app-signin-form',
  templateUrl: './signin-form.component.html',
  styleUrls: ['./signin-form.component.scss']
})
export class SigninFormComponent {
  readonly requestResetPasswordRoutingPath = ['/', AuthRoutingPath.password, PasswordRoutingPath.requestResetPassword];
  readonly FORM_KEYS = FORM_KEYS;
  loginForm = new FormGroup({
    [FORM_KEYS.USERNAME]: new FormControl(null, [Validators.required, EmailWithDomainValidator]),
    [FORM_KEYS.PASSWORD]: new FormControl(null, [Validators.required])
  });
  formSubmitted = false;

  constructor(
    private readonly authService: AuthService,
    private readonly toastService: ToastService,
    private workspaceService: WorkspaceService,
    private activatedRoute: ActivatedRoute
  ) {}

  signIn(): void {
    this.formSubmitted = true;
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    const requestData = this.getRequestData();
    const { returnUrl } = this.activatedRoute.snapshot.queryParams;
    const workspaceIdFromReturnUrl = returnUrl?.split('/')[2];

    if (this.loginForm.valid && requestData) {
      this.authService
        .signIn(requestData)
        .pipe(
          switchMap(() =>
            iif(
              () => !!workspaceIdFromReturnUrl,
              this.workspaceService.getWorkspaceById(workspaceIdFromReturnUrl),
              this.workspaceService.getInitialWorkspace()
            )
          ),
          tap((workspace: WorkspaceDto) => {
            if (workspace) {
              this.authService.redirectToInitialPage(workspace.id, returnUrl);
            }
          }),
          catchError(() => {
            // error message zwracany przez BE: 'Invalid credentials.'
            this.toastService.show('Email or password are incorrect. Please enter a valid credentials', {
              header: 'Login bad credentials',
              type: 'danger',
              progressBar: true,
              toastDecayProgress: 0
            });

            return EMPTY;
          })
        )
        .subscribe();
    } else {
      throw 'Unable to process Sign In data';
    }
  }

  private getRequestData(): Record<string, string> {
    const { [FORM_KEYS.USERNAME]: username, [FORM_KEYS.PASSWORD]: password } = this.loginForm.value;

    return {
      username,
      password
    };
  }
}
