<div
  [ngClass]="{ 'remove-confirmation-center-text': developmentType() !== DevelopmentType.instance }"
  class="remove-confirmation-modal-text"
>
  <div class="remove-confirmation" *ngIf="developmentType() !== DevelopmentType.instance">
    Are you sure you want to remove this {{ mainCategoryName() }}?
  </div>
  <div data-testid="item-delete-confirmation-message" class="remove-confirmation" *ngIf="developmentType() === DevelopmentType.instance">
    Are you sure you want to remove this {{ instanceNameSingular | lowercase }}?
  </div>
  <span data-testid="item-delete-danger-message" class="text-danger">This operation can not be undone!</span>
</div>
