import { HttpErrorResponse } from '@angular/common/http';

import { PreprocessedErrorDto } from '@shared/_models';

import { GENERIC_ERROR_MESSAGE } from './generic-error-message';
import { isPreprocessedError } from './is-prepocessed-error';

export function toMessage(errorResponse: HttpErrorResponse, location?: string, fallbackMessage = GENERIC_ERROR_MESSAGE.content): string {
  if (!errorResponse || !isPreprocessedError(errorResponse.error)) return fallbackMessage;

  const preprocessedError = errorResponse.error as PreprocessedErrorDto;

  if (location) {
    const findError = () => {
      const match = preprocessedError.errors.find(error => error.location === location);

      // For now only this very simplistic logic for identifying the error location by path
      return match || preprocessedError.errors.find(error => error.path?.includes(location));
    };
    const error = findError();

    return error?.message || fallbackMessage;
  }

  return preprocessedError.errors[0].message;
}
