<app-generic-modal
  [closeButton]="{ text: 'Cancel' }"
  [acceptButton]="{ text: 'Update' }"
  [developmentType]="developmentType()"
  [primary]="true"
  [hideHeaderCloseIcon]="true"
  (closeModal)="onClose($event)"
  class="smiles-wysiwyg-modal"
>
  <ng-template #headerTemplate>
    <app-modal-header-left
      header="Editing"
      imgSrc="molecule_icon.svg"
      [itemName]="fieldName()"
      [developmentType]="developmentType()"
      [categoryName]="categoryName()"
      [whiteIconFilter]="true"
      (closeModal)="onClose(CloseReason.DISMISS)"
    ></app-modal-header-left>
  </ng-template>
  <div class="smiles-wysiwyg-modal__body">
    <div>
      <iframe
        id="ifKetcher"
        src="../../../../../../../assets/ketcher-standalone_2.21.0/index.html"
        (load)="onKetcherLoaded()"
        class="smiles-wysiwyg-modal__body__iframe-ketcher"
        [class.smiles-wysiwyg-modal__body__iframe-ketcher--invalid]="error()"
        width="1100"
        height="550"
      ></iframe>
      <div class="smiles-wysiwyg-modal__body__error-message">
        @if (error()) {
          {{ error() }}
        }
      </div>
    </div>
  </div>
</app-generic-modal>
