import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { LowerCasePipe, NgIf, NgFor, NgClass } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { InputWithValidationComponent } from '@shared/_components/inputs/input-with-validation/input-with-validation.component';
import { OptionIconComponent } from '@shared/_components/option-icon/option-icon.component';
import { hasColor } from '@app/domain/field';
import { DynamicFieldBaseComponent } from '../dynamic-field-base/dynamic-field-base.component';
import { DEFAULT_SELECT_PLACEHOLDER } from '@app/domain/field/placeholders/dropdown';
import { DropdownSelectComponent } from '@shared/_components/inputs/dropdown-select/dropdown-select.component';

@Component({
  selector: 'app-form-select',
  standalone: true,
  imports: [
    LowerCasePipe,
    NgIf,
    NgFor,
    NgClass,
    InputWithValidationComponent,
    OptionIconComponent,
    ReactiveFormsModule,
    DropdownSelectComponent
  ],
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormSelectComponent extends DynamicFieldBaseComponent {
  options = computed<{ name: string; color: number }[]>(() =>
    this.config()?.options?.allowed_values?.map(allowedValue => ({ name: allowedValue.enum_value, color: allowedValue.enum_color }))
  );
  hasColor = computed<boolean>(() => hasColor(this.config()));
  readonly DEFAULT_SELECT_PLACEHOLDER = DEFAULT_SELECT_PLACEHOLDER;
}
