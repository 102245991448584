<div class="dropdown-select-container">
  <ng-select
    #select
    class="dropdown-select"
    [bindLabel]="bindLabel"
    popupClass="lg-dropdown-menu lg-dropdown-menu--y-scroll"
    [placeholder]="placeholder()"
    [clearable]="false"
    [editableSearchTerm]="false"
    [items]="items"
    (change)="onSelectItem($event, select)"
    (focusin)="onFocusin(select)"
    (blur)="onBlur()"
    [ngModel]="model()"
  >
    <ng-template ng-label-tmp let-item="item">
      <ng-container
        [ngTemplateOutlet]="dropdownOptionTemplate"
        [ngTemplateOutletContext]="{ item: item, colorsEnabled: colorsEnabled }"
      ></ng-container>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="dropdown-select-option" data-testid="select-option">
        <ng-container
          [ngTemplateOutlet]="dropdownOptionTemplate"
          [ngTemplateOutletContext]="{ item: item, colorsEnabled: colorsEnabled }"
        ></ng-container>
      </div>
    </ng-template>
  </ng-select>
  <button class="btn btn-clear px-3" *ngIf="model() && clearable" (click)="onClear()"></button>
  <ng-template #dropdownOptionTemplate let-item="item" let-colorsEnabled="colorsEnabled">
    <span *ngIf="colorsEnabled" class="status-icon" [ngClass]="'option-color-' + item.color"></span>
    <span class="dropdown-select-label" [ngClass]="{ 'dropdown-select-label-with-color': colorsEnabled }">
      {{ item.name }} {{ item.description | titlecase }}
    </span>
  </ng-template>
</div>
