import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { InfoPopoverProps } from './model/info-popover-props';

@Component({
  selector: 'app-info-popover',
  standalone: true,
  imports: [NgbPopover],
  template: `
    <button
      class="info-popover"
      [ngbPopover]="data().content"
      [popoverTitle]="data().title"
      placement="bottom-start"
      data-testid="info-popover"
    ></button>
  `,
  styleUrl: './info-popover.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoPopoverComponent {
  data = input.required<InfoPopoverProps>();
}
