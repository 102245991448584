<header>
  <h1>Polityka Prywatności</h1>
  <small>Last Update June 24th, 2024</small>
</header>
<article>
  <h3 appScrollSection="who-decides-about-processing-personal-data">Kto decyduje o przetwarzaniu Państwa danych osobowych w Serwisie?</h3>
  <p>
    Administratorem Państwa danych osobowych jest spółka Adamed Pharma S.A. z siedzibą w Pieńkowie, ul. Mariana Adamkiewicza 6A, 05-152
    Czosnów, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego pod numerem KRS 0000116926, przez Sąd Rejonowy dla m.st.
    Warszawy w Warszawie, XIV Wydział Gospodarczy KRS, posiadająca NIP: 731-17-51-025 oraz REGON: 472293752.
  </p>

  <h3 appScrollSection="bases-of-processing-personal-data">Podstawy i cele przetwarzania Państwa danych osobowych</h3>
  <p>Państwa dane osobowe przetwarzane będą w celu:</p>
  <ul>
    <li>
      Umożliwienia korzystania przez Użytkownika z Serwisu oraz założenia konta w Serwisie, na podstawie akceptacji Regulaminu tj. umowy
      zawartej pomiędzy Administratorem a Użytkownikiem (art. 6 ust. 1 lit. b) RODO);
    </li>
    <li>
      umożliwienia komunikacji z Użytkownikiem, na podstawie prawnie uzasadnionego interesu Administratora (art. 6 ust. 1 lit. f RODO);
    </li>
    <li>
      w celu obrony przed ewentualnymi roszczeniami, dochodzeniem roszczeń, a także w celu przeprowadzenia ewentualnego postępowania
      reklamacyjnego, na podstawie prawnie uzasadnionego interesu Administratora (art. 6 ust. 1 lit. f RODO).
    </li>
    <li>
      w celu prowadzenia postępowań reklamacyjnych − na podstawie art. 6 ust. 1 lit. f) RODO, tj. na podstawie prawnie uzasadnionego
      interesu realizowanego przez Administratora.
    </li>
  </ul>
  <p>
    Dane osobowe przetwarzane będą w powyżej wymienionych celach do momentu wygaśnięcia celu przetwarzania, zgłoszenia sprzeciwu, cofnięcia
    zgody lub do momentu przedawnienia roszczeń.
  </p>

  <h3 appScrollSection="data-recipients-information">Informacja o odbiorcach danych</h3>
  <p>
    Przewidywanymi odbiorcami Państwa danych są: operator techniczny Serwisu, dostawcy systemów teleinformatycznych, podwykonawcy, z którymi
    współpracuje Administrator w zakresie niezbędnym do realizacji przyjętych celów przetwarzania, podmioty powiązane z Administratorem,
    podwykonawcom realizującym usługi doradcze, analityczne, konsultingowe, kancelariom prawnym i podatkowym.
  </p>
  <p>Państwa dane osobowe mogą zostać udostępnione podmiotom i organom upoważnionym na podstawie przepisów prawa.</p>

  <h3 appScrollSection="do-we-use-cookies">Czy stosujemy pliki cookie?</h3>
  <p>W Serwisie wykorzystujemy wyłącznie niezbędne pliki cookie.</p>

  <h3 appScrollSection="your-rights">Jakie przysługują Państwu prawa?</h3>
  <p>
    Mają Państwo prawo dostępu do danych, żądania ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych,
    prawo do wniesienia sprzeciwu, a także prawo do wniesienia skargi do organu nadzorczego (Prezesa Urzędu Ochrony Danych Osobowych) w
    przypadku, gdy uznają Państwo, iż przetwarzanie danych osobowych narusza przepisy RODO. W celu zadania pytań, skierowania żądań lub
    zgłaszania skarg odnoszących się do ochrony Państwa danych osobowych należy kontaktować się z wyznaczonym przez Administratora
    inspektorem ochrony danych:
    <a href="mailto:iod@adamed.com.pl">iod&#64;adamed.com.pl</a>
    .
  </p>

  <h3 appScrollSection="how-contact-us">Jak skontaktować się z nami?</h3>
  <p>
    Jeżeli mają Państwo pytania lub wątpliwości w zakresie ochrony danych osobowych, prosimy o kontakt z wyznaczonym przez administratora
    Inspektorem Ochrony Danych (e-mail:
    <a href="mailto:iod@adamed.com.pl">iod&#64;adamed.com.pl</a>
    , adres korespondencyjny: Adamed Pharma S.A., z siedzibą przy ul. Mariana Adamkiewicza 6A, 05-152 Pieńków).
  </p>
</article>

<nav>
  <h2>Spis treści</h2>
  <ol>
    <li appScrollAnchor="who-decides-about-processing-personal-data">Kto decyduje o przetwarzaniu danych osobowych</li>
    <li appScrollAnchor="bases-of-processing-personal-data">Podstawy i cele przetwarzania danych</li>
    <li appScrollAnchor="data-recipients-information">Informacja o odbiorcach danych</li>
    <li appScrollAnchor="do-we-use-cookies">Czy stosujemy pliki cookie</li>
    <li appScrollAnchor="your-rights">Jakie przysługują Państwu prawa</li>
    <li appScrollAnchor="how-contact-us">Kontakt</li>
  </ol>
  <div appScrollAnchor="who-decides-about-processing-personal-data">
    Back to top
    <i class="bi bi-arrow-up"></i>
  </div>
</nav>
