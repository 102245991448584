import { DevelopmentItem, Field, ParameterDto, SectionDto, SectionType } from '@shared/_models';
import { toField } from '@shared/dto-adapters/field';
import { not } from '@shared/_helpers/fp';

import { comparePositions } from '../shared/compare-positions';
import { isPartOfComplexField } from '.';

function orderParameters(parameters: ParameterDto[]): ParameterDto[] {
  const mainParameters = parameters.filter(not(isPartOfComplexField)).sort(comparePositions);

  return mainParameters.flatMap(mainParameter => {
    const calculated = parameters.filter(parameter => parameter.value_from_id === mainParameter.id).sort(comparePositions);
    const modifications = parameters.filter(parameter => parameter.parent_id === mainParameter.id).sort(comparePositions);

    if (calculated.length || modifications.length) {
      return [mainParameter, ...calculated, ...modifications];
    }

    return [mainParameter];
  });
}

export function getOrderedFields(developmentItem: DevelopmentItem, sectionType: SectionType, includeWithoutValue = false): Field[] {
  if (!developmentItem || !sectionType) return [];

  const sections: SectionDto[] = developmentItem.template.sections.filter(section => section.type === sectionType).sort(comparePositions);
  const parameters: ParameterDto[] = sections.flatMap(section => orderParameters(section.fields));

  return parameters
    .map(parameter => {
      const valueDto = developmentItem.field_values.find(fieldValueDto => fieldValueDto.field_template_id === parameter.id);

      return toField(parameter, valueDto);
    })
    .filter(field => (includeWithoutValue ? true : !!field.value));
}
