import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

import { IconComponent } from '@app/shared/_components/icon/components/icon/icon.component';
import { IconColors } from '@app/shared/_components/icon/utils/icon-colors';
import { DropdownItem, ExperimentStatus, ExperimentUpdateDto } from '@app/shared/_models';
import { StatusIconPipe } from '@app/shared/pipes';

@Component({
  selector: 'app-experiment-status-dropdown',
  standalone: true,
  imports: [CommonModule, IconComponent, StatusIconPipe],
  templateUrl: './experiment-status-dropdown.component.html',
  styleUrls: ['./experiment-status-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentStatusDropdownComponent {
  @Input() selectedStatus: ExperimentStatus;
  @Output() update = new EventEmitter<Partial<ExperimentUpdateDto>>();

  @ViewChild('statusSelect') statusSelect: ElementRef;

  statusSelectItems: DropdownItem[] = Object.keys(ExperimentStatus).map(key => ({
    id: null,
    name: ExperimentStatus[key]
  }));
  IconColors = IconColors;
  isOpen = false;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  selectOption(option: DropdownItem): void {
    if (this.selectedStatus !== option.name) {
      this.selectedStatus = option.name as ExperimentStatus;
      this.onStatusChange(this.selectedStatus);
    }
    this.isOpen = false;
  }

  private onStatusChange(status: ExperimentStatus) {
    this.selectedStatus = status;
    this.update.emit({ status: this.selectedStatus });
  }
}
