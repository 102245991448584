import { MemberPermission, ProjectDto, UpdateProjectDto } from '@shared/_models';

export function getPayloadForChangeRoleToLead(userId: string, project: ProjectDto): Partial<UpdateProjectDto> {
  const newLead = project.members.find(member => member.user.id === userId);
  return {
    members: project.members
      .filter(member => member.user.id !== userId)
      .map(member => ({ user_id: member.user.id, permission: member.permission })),
    leads: [newLead.user, ...project.leads].map(lead => lead.id)
  };
}

export function getPayloadForAddMember(userId: string, project: ProjectDto): Partial<UpdateProjectDto> {
  return {
    members: project.members
      .map(member => ({ user_id: member.user.id, permission: member.permission }))
      .concat({ user_id: userId, permission: MemberPermission.READ })
  };
}

export function getPayloadForChangeRoleToMember(
  userId: string,
  project: ProjectDto,
  permission: MemberPermission
): Partial<UpdateProjectDto> {
  const userToUpdateIsMember = project.members.some(member => member.user.id === userId);

  const updatedLeads = project.leads.filter(lead => lead.id !== userId).map(lead => lead.id);

  const updatedMembers = project.members.map(member => ({
    user_id: member.user.id,
    permission: member.user.id === userId ? permission : member.permission
  }));

  if (!userToUpdateIsMember) {
    updatedMembers.push({ user_id: userId, permission: permission });
  }

  return { leads: updatedLeads, members: updatedMembers };
}

export function getPayloadForChangeRoleToOwner(userId: string, project: ProjectDto): Partial<UpdateProjectDto> {
  return {
    user_created: userId,
    leads: project.leads
      .map(lead => lead.id)
      .filter(id => id !== userId)
      .concat(project.user_crt.id),
    members: project.members
      .filter(member => member.user.id !== userId)
      .map(member => ({
        user_id: member.user.id,
        permission: member.permission
      }))
  };
}

export function getPayloadForRemoveUserFromProject(userId: string, project: ProjectDto): Partial<UpdateProjectDto> {
  return {
    leads: project.leads.map(lead => lead.id).filter(id => id !== userId),
    members: project.members
      .filter(member => member.user.id !== userId)
      .map(member => ({
        user_id: member.user.id,
        permission: member.permission
      }))
  };
}
