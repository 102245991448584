<div class="field-controls" [ngClass]="{ 'field-controls--hovered': hovered, invisible: !visible }" data-testid="field-controls">
  <div class="flex-fill">
    <button>
      <img src="../../../../assets/icons/drag_drop_icon.svg" class="field-controls__drag-icon" />
    </button>
  </div>
  <ng-container *ngIf="showActionBtns">
    <button (click)="edit.emit()" data-testid="edit-button">
      <img src="../../../../assets/icons/pen_icon.svg" class="field-controls__edit-icon" />
    </button>
    <button class="mx-1" (click)="onCopyBtnClick()">
      <img src="../../../../assets/icons/copy_icon.svg" class="field-controls__edit-icon" />
    </button>
    <button (click)="delete.emit()" data-testid="delete-button">
      <img src="../../../../assets/icons/trash_black_icon.svg" class="field-controls__delete-icon" />
    </button>
  </ng-container>
</div>

<ng-template #notImplementedModal let-modal>
  <!-- Komponent był źle napisany. Tak niskopoziomowy komponent wogole nie powienien patrzec na routing.
       Pozatym informacja o developmentType prawie nigdy nie wystepuje w routingu(jako parameter).
       Blad zalatany (pyt. czy wogole warto to naprawiac)  -->
  <app-not-implemented-modal [developmentType]="DevelopmentType.researchObject" (closeModal)="modal.close()"></app-not-implemented-modal>
</ng-template>
