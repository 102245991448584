<div
  class="text-long"
  [ngClass]="{
    'text-long--invalid': markAsInvalid
  }"
>
  <div class="text-long__scroll">
    <textarea
      #textarea
      rows="5"
      class="text-long__scroll__textarea"
      [ngModel]="ngModel()"
      [placeholder]="placeholder"
      (keydown)="onKeydown($event)"
      (focus)="setHeightToContent($event.target)"
      (input)="setHeightToContent($event.target)"
      (ngModelChange)="onNgModelChange($event)"
      (blur)="blurred.emit()"
    ></textarea>
  </div>
</div>
<app-text-counter *ngIf="counterOptions?.show" [length]="ngModel()?.length || 0" [maxLength]="counterOptions.maxLength"></app-text-counter>
