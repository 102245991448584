import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { WorkspaceService } from '@app/_workspaces/workspace/workspace.service';
import { getOrderedFields } from '@app/domain/field';
import { selectSelectedProject } from '@app/shared/_root-store/projects-store/projects.selectors';
import { trackByIndex } from '@shared/_helpers';
import { Experiment, Field, SectionType, DisplayFieldType } from '@shared/_models';
import { ExperimentsStoreService } from '@shared/_root-store/experiments-store/experiments-store.service';
import { selectAvatarForId } from '@shared/_root-store/users-store/users.selectors';
import { ExperimentService } from '@shared/_services/experiment';
import { ExperimentParamsService } from '@shared/_services/experiment/experiment-params.service';
import { NavigationService } from '@shared/_services/navigation.service';

import { TableItemBaseComponent } from '../table-item/table-item.component';

@Component({
  selector: 'app-experiment-table-item',
  templateUrl: './experiment-table-item.component.html',
  styleUrls: ['./experiment-table-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentTableItemComponent extends TableItemBaseComponent<Experiment> {
  imageAreaHidden = input<boolean>();
  parametersAreaHidden = input<boolean>();
  resultsAreaHidden = input<boolean>();
  showResearchObjects = input.required<boolean>();
  avatar$: Observable<string> = toObservable(this.itemData).pipe(
    switchMap(experiment => this.store.select(selectAvatarForId(experiment?.user_crt.id))),
    map(avatar => avatar?.base64 ?? null)
  );
  showRelatedResearchObjects = false;
  workspaceId = this.workspaceService.currentWorkspaceId();
  relatedResearchObjectsNames = computed<string[]>(() => this.itemData()?.linked_objects.map(ro => ro.ro_name));
  results = computed<Field[]>(() => getOrderedFields(this.itemData(), SectionType.RESULTS));
  parameters = computed<Field[]>(() => getOrderedFields(this.itemData(), SectionType.PARAMETERS));
  parameter = computed<Field>(() => this.parameters()?.[0]);
  showImages = computed(() => this.itemData()?.template.hidden_section_types?.includes(SectionType.IMAGES));
  showParameters = computed(() => this.itemData()?.template.hidden_section_types?.includes(SectionType.PARAMETERS));
  showResults = computed(() => this.itemData()?.template.hidden_section_types?.includes(SectionType.RESULTS));
  showDetails = computed(() => this.itemData()?.linked_objects?.length > 0 && this.showResearchObjects());
  selectedProject$ = this.store.select(selectSelectedProject);
  contextMenuConfig = {
    title: 'Actions',
    actions: [
      {
        name: 'Export to XLS',
        type: 'export',
        fn: ($event: Event, itemData: Experiment) => this.exportToXLS($event, itemData)
      },
      {
        name: 'Delete experiment',
        type: 'delete',
        fn: ($event: Event, itemData: Experiment) => this.delete($event, itemData)
      }
    ]
  };

  readonly trackByIndex = trackByIndex;
  readonly DisplayFieldType = DisplayFieldType;

  constructor(
    public readonly experimentsStoreService: ExperimentsStoreService,
    public readonly navigationService: NavigationService,
    private readonly experimentService: ExperimentService,
    store: Store,
    private workspaceService: WorkspaceService,
    private readonly experimentParamsService: ExperimentParamsService
  ) {
    super(store);
  }

  toggleExperimentSelection($event, id: string): void {
    this.experimentService.toggleExperimentSelection($event, id);
  }

  isExperimentSelected(experimentId: string): Observable<boolean> {
    return this.experimentService.isExperimentSelected(experimentId);
  }

  switchShowRelatedResearchObjects(showRelatedResearchObjects: boolean) {
    this.showRelatedResearchObjects = showRelatedResearchObjects;
  }

  private exportToXLS($event: Event, experiment: Experiment): void {
    $event.preventDefault();
    $event.stopPropagation();

    const experimentName = experiment.name.split(' ').join('-');
    const fileName = `exp-${experimentName}`;

    this.experimentsStoreService.generateCSV(fileName, [experiment.id]);
  }

  private delete($event: Event, experiment: Experiment): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.experimentsStoreService.deleteExperiment(experiment.id, this.experimentParamsService.getHttpParams());
  }
}
