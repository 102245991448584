<app-generic-modal
  [closeButton]="{ text: 'Cancel' }"
  [acceptButton]="{ text: 'Save' }"
  [developmentType]="developmentType"
  [primary]="true"
  [hideHeaderCloseIcon]="true"
  (closeModal)="close($event)"
>
  <ng-template #headerTemplate>
    <ng-template [ngTemplateOutlet]="modalHeaderTemplate"></ng-template>
  </ng-template>
  <form [formGroup]="form">
    <app-input-with-validation
      [backendErrorMessage]="errorMessage$ | async"
      [control]="form.controls.name"
      [config]="{
        label:
          developmentType === 'experiment'
            ? project()?.development_type_names.experiments + ' name'
            : project()?.development_type_names.research_objects + ' name'
      }"
    >
      <app-input-with-clear
        [formControl]="form.controls.name"
        [hasError]="form.controls.name.touched && (form.controls.name.invalid || (errorMessage$ | async))"
        placeholder="Name for {{
          developmentType === 'experiment'
            ? project()?.development_type_names.experiments
            : project()?.development_type_names.research_objects
        }}"
      ></app-input-with-clear>
    </app-input-with-validation>
  </form>
</app-generic-modal>

<ng-template #modalHeaderTemplate let-modal>
  <app-modal-header-left
    header="Editing name for"
    [imgSrc]="developmentType === 'experiment' ? 'experiment_icon_with_bg.svg' : 'research_object_icon_with_bg.svg'"
    [itemName]="
      developmentType === 'experiment' ? project()?.development_type_names.experiments : project()?.development_type_names.research_objects
    "
    [categoryName]="itemDetailsName"
    [developmentType]="developmentType"
    (closeModal)="closeModal.emit()"
  ></app-modal-header-left>
</ng-template>
