import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';

import { ToastService } from '@shared/_modules/toast/toast.service';
import { GENERIC_ERROR_MESSAGE, toMessage } from '@app/domain/error-handling';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
  #genericError$ = new Subject<void>();

  constructor(private toastService: ToastService) {
    this.#handleGenericError();
  }

  // TODO: Przedyskutowac czy naprawde chcemy dla kazdego exception ktore pada w
  // runtime JS'a wyświetlać toast dla uytkownika
  handleError(errorResponse: Error | HttpErrorResponse): void {
    // always log error
    console.log(errorResponse);

    const toastMessage = toMessage(errorResponse as HttpErrorResponse, null, null);

    if (toastMessage) {
      this.showToast(toastMessage, 'Error');
      return;
    }

    this.#genericError$.next();
  }

  showToast(content: string, header: string) {
    this.toastService.show(content, {
      header: header,
      type: 'danger'
    });
  }

  #handleGenericError() {
    const { content, header } = GENERIC_ERROR_MESSAGE;

    this.#genericError$
      .pipe(
        throttleTime(250),
        tap(() => {
          this.showToast(content, header);
        })
      )
      .subscribe();
  }
}
