<ng-container *ngIf="!imageAreaHidden && mainImageBase64$ | async as mainImageBase64; else noImageTpl">
  <div (mouseenter)="loadMagnifiedImage()" class="lg-table__image-container" data-test="imagePresent">
    <app-image [base64]="mainImageBase64"></app-image>
  </div>
  <div class="lg-table__image-container--magnified">
    <app-image [base64]="mainMediumImageBase64$ | async" class="lg-table__image-container--magnified-image"></app-image>
    <div class="lg-table__image-container--magnified-image-name">
      {{ (mainImage$ | async)?.name }}
    </div>
  </div>
</ng-container>
<ng-template #noImageTpl>
  <div class="lg-table__image-container lg-table__image-container--no-img-placeholder">
    <i
      class="lg-table__image-placeholder"
      [ngClass]="{
        'experiment-placeholder': developmentType === DevelopmentType.experiment,
        'research-object-placeholder': developmentType === DevelopmentType.researchObject
      }"
    ></i>
  </div>
</ng-template>
