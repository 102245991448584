<div
  class="datatable__additional"
  [ngClass]="{
    'datatable__additional--selected': showRelatedResearchObjects === true
  }"
>
  <app-table-item-details
    (showRelatedItemsChanged)="switchShowRelatedResearchObjects($event)"
    detailsMessage="Objects"
  ></app-table-item-details>
  <ng-container *ngIf="showRelatedResearchObjects">
    <div class="datatable__additional-header align-items-center">
      <div class="col image-col">{{ (areaName$ | async)[SectionType.IMAGES] }}</div>
      <div class="col oid-col">ID</div>
      <div class="col">Name</div>
      <div class="col">Category</div>
      <div class="col">Added By</div>
      <div class="col">{{ (areaName$ | async)[SectionType.INSTANCES] }} ID</div>
      <div class="col used-in-exp-col">Used In {{ (selectedProject$ | async)?.development_type_names.experiments }}</div>
      <div class="col date-added-col">Date Added</div>
      <div class="go-to-ro-col"></div>
    </div>
    <app-experiment-table-item-linked-objects
      class="datatable__additional-row"
      *ngFor="let relatedRO of experiment.linked_objects"
      [linkedObject]="relatedRO"
      (goToResearchObjectInfo)="goToResearchObjectInfo($event.researchObjectId, $event.researchObjectCategoryId)"
    ></app-experiment-table-item-linked-objects>
  </ng-container>
</div>
