import { computed, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { WorkspaceDto } from '@shared/_models/workspace';
import * as actions from '@shared/_root-store/workspaces-store/workspaces.actions';
import { selectSelectedWorkspace } from '@shared/_root-store/workspaces-store/workspaces.selectors';
import { WorkspaceApiService } from '@shared/_services/workspace';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  currentWorkspace = toSignal(this.store.select(selectSelectedWorkspace));
  currentWorkspaceId = computed<string>(() => this.currentWorkspace()?.id);

  constructor(
    private store: Store,
    private workspaceApiService: WorkspaceApiService,
    private router: Router
  ) {}

  // Initial workspace to zawsze favourite workspace, jeśli nie ma favourite workspace to jest to error
  getInitialWorkspace(): Observable<WorkspaceDto> {
    return this.workspaceApiService.getFavouriteWorkspace().pipe(
      tap(workspace => {
        if (!workspace) {
          return this.router.navigateByUrl('app/error');
        }
        this.store.dispatch(actions.loadFavouriteWorkspaceSuccess({ workspace }));
      }),
      catchError(errorResponse => {
        this.store.dispatch(actions.loadFavouriteWorkspaceFailure({ errorResponse }));
        this.router.navigateByUrl('app/error');

        return EMPTY;
      })
    );
  }

  getWorkspaceById(workspaceId: string): Observable<WorkspaceDto> {
    return this.workspaceApiService.getWorkspace(workspaceId).pipe(
      tap(workspace => {
        if (!workspace) {
          return this.router.navigateByUrl('app/error');
        }
      }),
      catchError(errorResponse => {
        this.router.navigateByUrl('app/error');

        return EMPTY;
      })
    );
  }
}
