<app-generic-modal
  [closeButton]="closeButton"
  [acceptButton]="acceptButton"
  [developmentType]="developmentType"
  [hideHeaderCloseIcon]="true"
  [hideFooter]="sectionName === 'import' ? true : false"
  class="item-details-info"
  (closeModal)="close($event)"
>
  <ng-template #headerTemplate>
    <ng-template [ngTemplateOutlet]="modalHeaderTemplate"></ng-template>
  </ng-template>
  <div class="info-modal__body">
    <div *ngIf="sectionViewMode !== SectionViewMode.edit && sectionName !== 'import'" class="info-modal__body-header">
      {{ sectionName }}
    </div>
    <div *ngIf="sectionViewMode === SectionViewMode.edit" class="info-modal__body-header-edit">Editing {{ sectionName }}</div>
    <ng-content></ng-content>
  </div>
</app-generic-modal>

<ng-template #modalHeaderTemplate>
  <app-modal-header-left
    [header]="header | capitalizeFirst"
    [developmentType]="developmentType"
    [imgSrc]="imgSrc"
    [itemName]="itemName"
    [categoryName]="categoryName"
    (closeModal)="closeModal.emit()"
  ></app-modal-header-left>
</ng-template>
