import { DevelopmentType, SectionTypeNamesDto, SectionType } from '@shared/_models';

export function getAreaPropertyName(sectionType: SectionType, developmentType: DevelopmentType): keyof SectionTypeNamesDto {
  if (developmentType === DevelopmentType.researchObject) {
    if (sectionType === SectionType.IMAGES) {
      return 'ro_images';
    }

    if (sectionType === SectionType.PARAMETERS) {
      return 'ro_parameters';
    }

    if (sectionType === SectionType.INSTANCES) {
      return 'ro_samples';
    }

    throw new Error('Invalid getAreaPropertyName function parameters');
  }

  if (developmentType === DevelopmentType.experiment) {
    if (sectionType === SectionType.IMAGES) {
      return 'exp_images';
    }

    if (sectionType === SectionType.RESULTS) {
      return 'exp_results';
    }

    if (sectionType === SectionType.PARAMETERS) {
      return 'exp_parameters';
    }

    throw new Error('Invalid getAreaPropertyName function parameters');
  }
}
