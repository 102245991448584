import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { ImageComponent } from '@shared/_components/image/components/image.component';
import { FieldValuePreviewComponent } from '@shared/_components/item/item-details/item-details-grid-section/field-value-preview/field-value-preview.component';
import { ItemsCounterComponent } from '@shared/_components/item/items-counter/items-counter.component';
import { UserAvatarComponent } from '@shared/_components/user-avatar/user-avatar.component';
import { WysiwygFieldComponent } from '@shared/_components/wysiwyg-field/wysiwyg-field.component';

import { ExperimentTableItemComponent } from './experiment-table-item/experiment-table-item.component';
import { ExperimentTableItemDetailsComponent } from './experiment-table-item-details/experiment-table-item-details.component';
import { ExperimentTableItemLinkedObjectsComponent } from './experiment-table-item-linked-objects/experiment-table-item-linked-objects.component';
import { ExperimentTableListComponent } from './experiment-table-list/experiment-table-list.component';
import { FileTableItemComponent } from './file-table-item/file-table-item.component';
import { FileTableListComponent } from './file-table-list/file-table-list.component';
import { InstanceTableItemComponent } from './instance-table-item/instance-table-item.component';
import { InstanceTableListComponent } from './instance-table-list/instance-table-list.component';
import { ResearchObjectTableItemComponent } from './research-object-table-item/research-object-table-item.component';
import { ResearchObjectTableItemDetailsComponent } from './research-object-table-item-details/research-object-table-item-details.component';
import { ResearchObjectTableListComponent } from './research-object-table-list/research-object-table-list.component';
import { TableItemDetailsComponent } from './table-item-details/table-item-details.component';
import { TableItemImageComponent } from './table-item-image/table-item-image.component';
import { TableItemParameterComponent } from './table-item-parameter/table-item-parameter.component';
import { TableItemResultComponent } from './table-item-result/table-item-result.component';
import { TableListComponent } from './table-list/table-list.component';
import { SortingRotationStatePipe } from '../../../shared/pipes';
import { OptionIconComponent } from '../../_components/option-icon/option-icon.component';
import { ExperimentStatusModule } from '../../_modules/experiment-status/experiment-status.module';
import { SkeletonsModule } from '../../_modules/skeletons/skeletons.module';
import { FileWithoutExtensionPipe } from '../../pipes/file-without-extension.pipe';

const COMPONENTS = [
  TableListComponent,
  ExperimentTableListComponent,
  ExperimentTableItemComponent,
  ResearchObjectTableListComponent,
  ResearchObjectTableItemComponent,
  TableItemResultComponent,
  InstanceTableListComponent,
  InstanceTableItemComponent,
  ExperimentTableItemDetailsComponent,
  ResearchObjectTableItemDetailsComponent,
  TableItemDetailsComponent
];

const NG_MODULES = [CommonModule, RouterModule];

const LIB_MODULES = [ScrollingModule, NgbTooltipModule, NgbPopoverModule];

const MODULES = [
  ExperimentStatusModule,
  // ItemSelectCheckboxModule,
  SkeletonsModule,
  ItemsCounterComponent
];

const PIPES = [SortingRotationStatePipe, FileWithoutExtensionPipe];
const STANDALONE_COMPONENTS = [
  ImageComponent,
  OptionIconComponent,
  UserAvatarComponent,
  FieldValuePreviewComponent,
  WysiwygFieldComponent,
  ExperimentTableItemLinkedObjectsComponent,
  TableItemImageComponent,
  TableItemParameterComponent,
  FileTableItemComponent,
  FileTableListComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...NG_MODULES, ...LIB_MODULES, ...MODULES, ...PIPES, ...STANDALONE_COMPONENTS],
  exports: [...COMPONENTS, ...PIPES, ...MODULES, ...STANDALONE_COMPONENTS]
})
export class TableModule {}
