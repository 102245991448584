<app-editor
  [formControl]="control()"
  [label]="config()?.label"
  [maxLength]="maxLength()"
  [counterOptions]="counterOptions()"
  [backendErrorMessage]="backendErrorMessage()"
  [externalTouched]="externalTouched()"
  [markAsInvalid]="markAsInvalid()"
  [attr.data-test]="'sectionTextarea'"
  (blurred)="blur$.next()"
  ngDefaultControl
></app-editor>
